@charset "UTF-8";
/*定义公共部分通用css，默认所有页面都需要加载 2016/08/17*/
* {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background: #fff;
}

input,
select,
textarea,
button {
  font-family: "Arial,Helvetica,Microsoft YaHei";
}

select,
input,
button {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
i,
b {
  font-weight: normal;
}

i {
  font-style: normal;
}

ul,
ol,
li {
  list-style-type: none;
}

img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;
}

img:not([src]) {
  opacity: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

/* 取消苹果圆角 */
input,
button,
textarea {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
}

input {
  font-size: 14px;
}

button {
  padding: 0;
}

p {
  padding: 0;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

input[disabled],
textarea[diabled] {
  color: #666;
  color: var(--font-normal);
  -webkit-text-fill-color: #666;
  -webkit-text-fill-color: var(--font-normal);
  opacity: 1;
}

/* placeholder  */
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #b3b3b3;
  color: var(--disabled);
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #b3b3b3;
  color: var(--disabled);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b3b3b3;
  color: var(--disabled);
}

input[disabled]::-webkit-input-placeholder,
textarea[diabled]::-webkit-input-placeholder {
  color: #b3b3b3;
  color: var(--disabled);
  -webkit-text-fill-color: #b3b3b3;
  -webkit-text-fill-color: var(--disabled);
}

button,
input[type=button] {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[readonly] {
  -webkit-user-select: none;
}

.h {
  display: none;
  visibility: visible;
}

.fix {
  *zoom: 1;
}

.fix:after {
  clear: both;
  content: "";
  display: table;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.pos-f {
  position: fixed;
}

.tc {
  text-align: center !important;
}

.tl {
  text-align: left !important;
}

.tr {
  text-align: right !important;
}

.cur {
  cursor: pointer;
}

.align-center {
  align-content: center;
  align-items: center;
  justify-content: center;
}

.dis-show {
  display: block !important;
}

.disShow {
  display: inline-block !important;
}

.dis-flex {
  display: flex !important;
}

.dis-hide {
  display: none !important;
}

.dis-inb {
  display: inline-block !important;
}

.dis-b {
  display: block;
}

.va-t {
  vertical-align: top;
}

.va-m {
  vertical-align: middle;
}

.va-b {
  vertical-align: bottom;
}

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml12 {
  margin-left: 12px;
}

.mr0 {
  margin-right: 0 !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb8 {
  margin-bottom: 8px;
}

.mt300 {
  margin-top: 300px !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.plr12 {
  padding: 0 12px;
}

.pb0 {
  padding-bottom: 0 !important;
}

.txt-hint {
  color: #4754ff;
  color: var(--color-hint);
}

.txt-red {
  color: #F43005 !important;
  color: var(--orangey-red) !important;
}

.txt-green {
  color: #4db814 !important;
  color: var(--font-green) !important;
}

.txt-tips {
  color: #999 !important;
  color: var(--font-tips) !important;
}

.txt-title {
  color: #000 !important;
  color: var(--font-title) !important;
}

.txt-main-color {
  color: #4754ff !important;
  color: var(--color) !important;
}

.txt-hover {
  cursor: pointer;
}
.txt-hover:hover {
  color: #4754ff !important;
  color: var(--color) !important;
}

.bg-white {
  background: #fff !important;
}

.flex {
  display: flex;
}

.flex-children {
  flex: 1 1;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-2 {
  display: -webkit-flex;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: normal;
}

.bl {
  border-left: 1px solid #DDDDDD;
  border-left: 1px solid var(--border-deep);
}

.br {
  border-right: 1px solid #DDDDDD;
  border-right: 1px solid var(--border-deep);
}

.bt {
  border-top: 1px solid #DDDDDD;
  border-top: 1px solid var(--border-deep);
}

.bb {
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}

.b0 {
  border: 0 none;
}

.mask, .mask-son, .mask-pop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  background: rgba(26, 26, 26, 0.3);
}

.mask {
  z-index: 3;
}

.mask-son {
  z-index: 5;
}

.mask-pop {
  z-index: 7;
}

.page-wrap {
  max-width: 1200px;
  margin: 0 auto;
}

.page-box-wrap {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-body {
  flex: 1 1;
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.page-body .page-main {
  flex: 1 1;
  width: 0;
}

.page-max {
  width: 100vw;
}

.bg-white {
  background: #fff;
}

.login-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 304px;
  transform: translate(-50%, -50%);
}
.login-modal .login-modal-title {
  width: 304px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  text-align: center;
  background: #fff;
  color: #000;
  color: var(--font-title);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.login-modal .login-modal-body {
  padding: 32px 0 32px;
  text-align: center;
  background: #fff;
}
.login-modal .login-modal-body .btn {
  width: 240px;
  height: 50px;
  line-height: 1;
  margin-bottom: 16px;
  border-radius: 50px;
  font-size: 18px;
  color: #666;
  color: var(--font-normal);
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.login-modal .login-modal-body .btn-confirm {
  background: #4754ff;
  background: var(--color);
  color: #fff;
}
.login-modal .login-modal-body .btn-confirm:hover {
  background: rgba(71, 84, 255, 0.8);
  background: var(--color-active);
}
.login-modal .close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.login-modal .close:after, .login-modal .close:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #999;
}
.login-modal .close:hover:after, .login-modal .close:hover:before {
  background: #666;
}
.login-modal .close:after {
  width: 1px;
  height: 14px;
}
.login-modal .close:before {
  width: 14px;
  height: 1px;
}

.null-list-box {
  text-align: center;
  padding-top: 250px;
  padding-bottom: 1em;
}
.null-list-box img {
  width: 200px;
  height: 162px;
}
.null-list-box .null-tips {
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}

.inquiry-model {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 300px;
  min-height: 200px;
  border-radius: 6px;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow: hidden;
  padding-bottom: 10px;
}
.inquiry-model .close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.inquiry-model .close:hover {
  opacity: 0.8;
}
.inquiry-model .close:after, .inquiry-model .close:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #999;
  background: var(--font-tips);
}
.inquiry-model .close:after {
  width: 1px;
  height: 16px;
}
.inquiry-model .close:before {
  width: 16px;
  height: 1px;
}
.inquiry-model .inquiry-model-title {
  padding: 32px 24px;
  font-size: 16px;
  text-align: center;
  background: #fff;
  color: #000;
  color: var(--font-title);
  font-weight: bold;
  word-break: break-all;
}
.inquiry-model .btn {
  display: block;
  width: 200px;
  height: 40px;
  margin: 0 auto 16px;
  background: #fcfcfc;
  background: var(--border-light);
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  color: #000;
  color: var(--font-title);
  border-radius: 50px;
  cursor: pointer;
}
.inquiry-model .btn:hover {
  opacity: 0.8;
}

.page-custom-modal .ant-modal-header {
  padding: 14px 24px;
  text-align: center;
  background: #E3E3E3;
}
.page-custom-modal .ant-modal-title {
  font-size: 18px;
  color: #000;
  color: var(--font-title);
}
.page-custom-modal .ant-modal-close-x {
  width: 48px;
  height: 48px;
}

.chatbot-modal {
  border: none;
  position: fixed;
  box-shadow: rgba(150, 150, 150, 0.2) 0px 10px 30px 0px, rgba(150, 150, 150, 0.2) 0px 0px 0px 1px;
  bottom: 20px;
  right: 62px;
  height: calc(-56px + 100vh);
  width: 638px;
  border-radius: 12px;
  display: block;
  z-index: 99999;
  overflow: hidden;
  background-color: rgb(243, 244, 246);
}
.chatbot-modal .chatbot-modal-title {
  height: 54px;
  line-height: 54px;
  text-align: center;
  background: #000;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.model-height-450 .ant-modal-content .ant-modal-body {
  height: 450px;
  overflow: auto;
}

.model-height-573 .ant-modal-content .ant-modal-body {
  height: 573px;
  overflow: auto;
}

.null-tips-box {
  text-align: center;
  padding-top: 100px;
}
.null-tips-box .null-icon {
  display: inline-block;
}
.null-tips-box .null-icon .svg-icon {
  width: 90px;
  height: 90px;
}
.null-tips-box .txt {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
}

.user-card {
  display: flex;
  align-content: center;
  align-items: center;
}
.user-card .head-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.user-card .head-img img, .user-card .head-img .svg-icon {
  width: 100%;
  height: 100%;
}
.user-card .main-box {
  flex: 1 1;
  font-size: 12px;
  line-height: 1.2;
}
.user-card .main-box .text-title {
  color: #000;
  color: var(--font-title);
}
.user-card .main-box .text-normal {
  margin-top: 4px;
  color: #666;
  color: var(--font-normal);
}
.user-card .main-box .text-tips {
  margin-top: 4px;
  color: #999;
  color: var(--font-tips);
}

.statistic-box-card {
  display: flex;
}
.statistic-box-card.statistic-box-card-divider .statistic-item-card:first-child:before {
  display: none;
}
.statistic-box-card.statistic-box-card-divider .statistic-item-card:before {
  content: "";
  width: 1px;
  height: 24px;
  background: #dcdcdc;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.statistic-box-card .statistic-item-card {
  flex: 1 1;
  width: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.statistic-box-card .statistic-item-card .statistic-title {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
  margin-top: 4px;
}
.statistic-box-card .statistic-item-card .statistic-main {
  font-size: 20px;
  color: #000;
  color: var(--font-title);
  font-weight: bold;
}
.statistic-box-card .statistic-item-card .txt-hint {
  color: #4754ff;
  color: var(--font-high);
}

.cascader-box {
  width: 100%;
}

.cont-table table {
  width: 100%;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  table-layout: fixed;
}
.cont-table th {
  height: 40px;
  vertical-align: middle;
  background: #f5f5f5;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding-left: 14px;
  text-align: left;
  line-height: 40px;
}
.cont-table tr:last-child {
  border-bottom: 0 none;
}
.cont-table td {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding-left: 14px;
  height: 40px;
  vertical-align: middle;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.cont-table td .info-box {
  padding: 8px 0;
  font-size: 14px;
}
.cont-table td .info-box .title {
  color: #000;
  color: var(--font-title);
  margin-bottom: 4px;
}
.cont-table td .info-box .tips {
  color: #999;
  color: var(--font-tips);
}
.cont-table td .txt {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cont-table td .img-box {
  display: inline-block;
  vertical-align: top;
  width: 60px;
  height: 60px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  text-align: center;
  line-height: 60px;
  margin: 8px 8px 8px 0;
}
.cont-table td .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.cont-table td .ope {
  width: auto;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
}
.cont-table td .ope:hover {
  color: #4754ff;
  color: var(--color);
}
.cont-table .table-body-empty {
  width: 968px;
  margin: 10px 0 30px;
}

.page-confirm-pay-modal .ant-modal-content {
  position: relative;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 430px;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box {
  width: 454px;
  height: 318px;
  margin-bottom: 20px;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box .close {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  font-size: 32px;
  transform: rotate(45deg);
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box .close:hover {
  cursor: pointer;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box img {
  width: 100%;
  height: 100%;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-body .confirm-txt {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-footer {
  display: flex;
  justify-content: center;
  padding: 30px 16px;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-footer button {
  width: 200px;
  height: 40px;
}
.page-confirm-pay-modal .ant-modal-content .ant-modal-footer button + button {
  margin-left: 30px;
}

:root {
  --color: #4754ff;
  --color-active: rgba(71, 84, 255, 0.8);
  --color-hover: rgba(71, 84, 255, .1);
  --color-hint: #4754ff;
  --border-deep: #DDDDDD;
  --border-middle: #DCE0E5;
  --border-light: #fcfcfc;
  --font-title: #000;
  --font-normal: #666;
  --font-tips: #999;
  --font-high: #4754ff;
  --disabled: #b3b3b3;
  --bg-color: #fDDDDDD;
  --btn-color: #ffb919;
  --orangey-red: #F43005;
  --orange: #ffb67a;
  --orange-hover: #cc9e38;
  --orange-red: rgba(255, 99, 80, 0.9);
  --font-green: #4db814;
}

.theme-dc3848 {
  --color: #DC3848;
  --color-active: rgba(231, 47, 62, 0.8);
  --color-hover: rgba(231, 47, 62, .1);
}

.theme-eb382f {
  --color: #eb382f;
  --color-active: #d1322a;
  --color-hover: rgba(235, 56, 47, .1);
}

.theme-fb6321 {
  --color: #fb6321;
  --color-active: #fc824d;
  --color-hover: rgba(251, 99, 33, .1);
}

.theme-b25724 {
  --color: #b25724;
  --color-active: #994b1f;
  --color-hover: rgba(178, 87, 36, .1);
}

.theme-dbaf58 {
  --color: #dbaf58;
  --color-active: #c29b4e;
  --color-hover: rgba(219, 175, 88, .1);
}

.theme-fab414 {
  --color: #fab414;
  --color-active: #e0a212;
  --color-hover: rgba(250, 180, 20, .1);
}

.theme-23982d {
  --color: #23982d;
  --color-active: #4fac57;
  --color-hover: rgba(35, 152, 45, .1);
}

.theme-f566d3 {
  --color: #f566d3;
  --color-active: #db5cbe;
  --color-hover: rgba(245, 102, 211, .1);
}

.theme-c38cfa {
  --color: #c38cfa;
  --color-active: #b07ee0;
  --color-hover: rgba(195, 140, 250, .1);
}

.theme-1c69e6 {
  --color: #1c69e6;
  --color-active: #185dcc;
  --color-hover: rgba(28, 105, 230, .1);
}

.theme-20ade5 {
  --color: #20ade5;
  --color-active: #4dbdea;
  --color-hover: rgba(32, 173, 229, .1);
}

.theme-0ec2b9 {
  --color: #0ec2b9;
  --color-active: #10E1D7;
  --color-hover: rgba(14, 194, 185, .1);
}

.theme-282828 {
  --color: #282828;
  --color-active: #494949;
  --color-hover: rgba(40, 40, 40, .1);
}

.theme-2d6285 {
  --color: #2d6285;
  --color-active: #285473;
  --color-hover: rgba(45, 98, 133, .1);
}

.page-reset p {
  margin: 0;
}
.page-reset .ant-spin {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-spin-dot-item {
  background-color: #4754ff;
  background-color: var(--color);
}
.page-reset .ant-pagination-item:hover, .page-reset .ant-pagination-item:focus {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-pagination-item:hover a, .page-reset .ant-pagination-item:focus a {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-pagination-item-active {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-pagination-item-active a {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-pagination-prev:focus a, .page-reset .ant-pagination-prev:hover a,
.page-reset .ant-pagination-next:focus a,
.page-reset .ant-pagination-next:hover a {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-pagination-prev:focus .ant-pagination-item-link, .page-reset .ant-pagination-prev:hover .ant-pagination-item-link,
.page-reset .ant-pagination-next:focus .ant-pagination-item-link,
.page-reset .ant-pagination-next:hover .ant-pagination-item-link {
  color: #4754ff;
  color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-select-selection:focus, .page-reset .ant-select-selection:hover {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-input:hover {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-input:focus {
  border-color: #4754ff;
  border-color: var(--color);
  box-shadow: 0 0 0 2px rgba(71, 84, 255, .1);
  box-shadow: 0 0 0 2px var(--color-hover);
}
.page-reset .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-btn-primary {
  background-color: #4754ff;
  background-color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
  color: #fff;
}
.page-reset .ant-btn-primary:hover, .page-reset .ant-btn-primary:focus {
  background-color: rgba(71, 84, 255, 0.8);
  background-color: var(--color-active);
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}
.page-reset .ant-btn-background-ghost.ant-btn-primary {
  color: #4754ff;
  color: var(--color);
  background-color: #4754ff;
  background-color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-btn-background-ghost.ant-btn-primary:hover, .page-reset .ant-btn-background-ghost.ant-btn-primary:focus {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
  background-color: rgba(71, 84, 255, 0.8);
  background-color: var(--color-active);
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}
.page-reset .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(71, 84, 255, .1);
  background-color: var(--color-hover);
}
.page-reset .ant-select-open .ant-select-selection {
  border-color: #4754ff;
  border-color: var(--color);
  box-shadow: 0 0 0 2px rgba(71, 84, 255, .1);
  box-shadow: 0 0 0 2px var(--color-hover);
}
.page-reset .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(71, 84, 255, .1);
  background-color: var(--color-hover);
}
.page-reset .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background-color: #4754ff;
  background-color: var(--color);
}
.page-reset .ant-calendar-date:hover {
  background: rgba(71, 84, 255, .1);
  background: var(--color-hover);
}
.page-reset .ant-calendar-range .ant-calendar-in-range-cell::before {
  background-color: rgba(71, 84, 255, .1);
  background-color: var(--color-hover);
}
.page-reset .ant-calendar-today .ant-calendar-date {
  color: #4754ff;
  color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #4754ff;
  background: var(--color);
}
.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: #4754ff;
  background: var(--color);
}
.page-reset .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #4754ff;
  background: var(--color);
}
.page-reset .ant-calendar-header a:hover {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #4754ff;
  background: var(--color);
}
.page-reset .ant-calendar-month-panel-month:hover {
  background: rgba(71, 84, 255, .1);
  background: var(--color-hover);
}
.page-reset .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4754ff;
  background-color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-checkbox-checked::after {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-checkbox-wrapper:hover .ant-checkbox-inner, .page-reset .ant-checkbox:hover .ant-checkbox-inner, .page-reset .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-typography a {
  color: inherit;
}
.page-reset .ant-typography a:focus, .page-reset .ant-typography a:hover {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-radio-checked .ant-radio-inner {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-radio-checked::after {
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
}
.page-reset .ant-radio-wrapper:hover .ant-radio, .page-reset .ant-radio:hover .ant-radio-inner, .page-reset .ant-radio-input:focus + .ant-radio-inner {
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}
.page-reset .ant-radio-inner::after {
  background-color: #4754ff;
  background-color: var(--color);
}
.page-reset textarea.ant-input {
  resize: none;
}
.page-reset .ant-radio-button-wrapper:hover {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
}
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #4754ff;
  color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
}
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}
.page-reset .ant-input-affix-wrapper:hover {
  border-color: #4754ff;
  border-color: var(--color);
  border-right-width: 1px !important;
}
.page-reset .ant-modal-title {
  text-align: center;
}
.page-reset .confirm-btn {
  width: 120px;
  height: 42px;
  text-align: center;
}
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #4754ff;
  background-color: var(--color);
}
.page-reset .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #4754ff;
  background-color: var(--color);
}
.page-reset .ant-pagination-next:focus .ant-pagination-item-link {
  background-color: #fff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}
.page-reset .ant-tabs-nav .ant-tabs-tab:hover {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-tabs-nav .ant-tabs-tab-active {
  color: #4754ff;
  color: var(--color);
}
.page-reset .ant-tabs-ink-bar {
  background: #4754ff;
  background: var(--color);
}
.page-reset .ant-carousel .slick-dots {
  z-index: 2;
}
.page-reset .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #4754ff;
  border-right-color: var(--color);
}

.floor-middle-box {
  max-width: 1200px;
  margin: 0 auto;
}

.floor-grail-layout {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.floor-grail-layout .layout-little-box {
  width: 200px;
}
.floor-grail-layout .layout-little-box .foods-floor-box-wrap {
  height: 100%;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 0 !important;
}
.floor-grail-layout .layout-little-box .goods-card {
  box-sizing: border-box;
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.floor-grail-layout .layout-little-box .goods-card .img-box {
  padding: 0;
}
.floor-grail-layout .layout-little-box .goods-card .info-box {
  padding: 0;
  margin-top: 12px;
}
.floor-grail-layout .layout-large-box {
  width: 990px;
}

.switcher-box {
  position: fixed;
  left: 50%;
  bottom: 12px;
  width: 540px;
  height: 60px;
  z-index: 3;
  transform: translateX(-50%);
  background: rgba(104, 104, 104, 0.86);
  box-shadow: 0 0 8px 0 rgba(123, 123, 123, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-left: 44px;
  color: #fff;
  border-radius: 100px;
}
.switcher-box .close-btn {
  position: absolute;
  left: 24px;
  cursor: pointer;
}
.switcher-box h2 {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
  padding-left: 12px;
}
.switcher-box .btn-box {
  font-size: 0;
  flex: 1 1;
  text-align: right;
}
.switcher-box .switch-btn {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: rgb(110, 206, 252);
  border-radius: 100px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  border: 0 none;
  margin-right: 24px;
  cursor: pointer;
}
.switcher-box .switch-btn:nth-child(2) {
  background: rgb(255, 109, 109);
  margin-right: 10px;
}
.switcher-box .switch-btn .iconfont {
  font-size: 14px;
  padding-right: 8px;
}

.switcher-right-box {
  position: fixed;
  right: 0;
  bottom: 36px;
  z-index: 3;
  background: rgba(104, 104, 104, 0.86);
  box-shadow: 0 0 8px 0 rgba(123, 123, 123, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  color: #fff;
  padding: 16px 12px 16px 14px;
  border-radius: 26px 0 0 26px;
}
.switcher-right-box .close-btn {
  display: none;
}
.switcher-right-box h2 {
  display: none;
}
.switcher-right-box .btn-box {
  font-size: 0;
  text-align: right;
}
.switcher-right-box .switch-btn {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: rgb(110, 206, 252);
  border-radius: 50%;
  font-size: 0;
  color: #fff;
  text-align: center;
  border: 0 none;
  cursor: pointer;
  margin-bottom: 18px;
}
.switcher-right-box .switch-btn:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #A1A1A1;
  position: absolute;
  left: 0;
  bottom: -7px;
}
.switcher-right-box .switch-btn:nth-child(1) {
  background: rgba(0, 0, 0, 0.2);
}
.switcher-right-box .switch-btn:nth-child(3) {
  background: rgb(255, 109, 109);
  margin-bottom: 0;
}
.switcher-right-box .switch-btn:nth-child(3):after {
  display: none;
}
.switcher-right-box .switch-btn .iconfont {
  font-size: 14px;
  line-height: 32px;
}

.pop-experience-tips {
  text-align: left;
}
.pop-experience-tips p {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  line-height: 1.5;
  margin-bottom: 10px;
}
.pop-experience-tips p .iconfont {
  margin-right: 6px;
}
.pop-experience-tips .btn {
  width: 100%;
  height: 36px;
  line-height: 36px;
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 18px;
  background-color: #4754ff;
  background-color: var(--color);
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.pop-experience-tips .btn:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.inquire-page-box {
  height: 100%;
  background: #fff;
  padding: 16px 16px 80px;
  position: relative;
}
.inquire-page-box .ant-spin-nested-loading > div > .ant-spin {
  top: 50%;
  transform: translateY(-50%);
}

.spin-box {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.inquire-head-wrap {
  display: flex;
  justify-content: space-between;
}
.inquire-head-wrap .search-wrap {
  width: 578px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  position: relative;
}
.inquire-head-wrap .search-wrap .search-box .txt {
  padding-right: 120px;
}
.inquire-head-wrap .search-wrap .search-box .txt .ant-input-suffix {
  right: 132px;
  z-index: 0;
  line-height: 38px;
}
.inquire-head-wrap .search-wrap .ant-input {
  border: 0 none;
  height: 38px;
}
.inquire-head-wrap .search-wrap .search-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 120px;
  background: #4754ff;
  background: var(--color);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: 0 none;
}
.inquire-head-wrap .search-wrap .search-btn:hover {
  opacity: 0.9;
}
.inquire-head-wrap .search-wrap .search-btn.search-btn-disabled {
  background: #b3b3b3;
  background: var(--disabled);
}
.inquire-head-wrap .search-wrap .search-btn i {
  font-size: 18px;
  margin-right: 4px;
}
.inquire-head-wrap .search-wrap .search-dropdown-box {
  position: absolute;
  top: 49px;
  left: 0;
  right: 0;
  height: 344px;
  background: #fff;
  box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #f0f0f0;
  z-index: 1;
}
.inquire-head-wrap .search-wrap .search-dropdown-box .search-dropdown-wrap {
  padding: 16px;
}
.inquire-head-wrap .search-wrap .search-dropdown-box .log-item {
  font-size: 12px;
  cursor: pointer;
  padding: 12px 0;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.inquire-head-wrap .search-wrap .search-dropdown-box .log-item:first-child {
  padding-top: 0;
}
.inquire-head-wrap .search-wrap .search-dropdown-box .log-item .tit {
  color: #000;
  color: var(--font-title);
}
.inquire-head-wrap .search-wrap .search-dropdown-box .log-item .txt {
  color: #999;
  color: var(--font-tips);
}
.inquire-head-wrap .search-wrap .search-dropdown-box .log-item .del:hover {
  color: #4754ff;
  color: var(--color);
}
.inquire-head-wrap .user-info {
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  cursor: pointer;
}
.inquire-head-wrap .user-info .txt {
  margin-left: 6px;
}
.inquire-head-wrap .user-info .btn {
  margin-left: 6px;
  color: #4754ff;
  color: var(--color);
}

.inquire-null-tips-box {
  width: 100%;
  text-align: center;
  padding-top: 80px;
}

.car-info-box {
  display: flex;
  padding: 16px;
  background: #FAFAFA;
  margin-top: 16px;
  position: relative;
}
.car-info-box:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #fafafa;
  position: absolute;
  top: -8px;
  left: 40px;
  transform: rotate(45deg);
}
.car-info-box .car-img {
  width: 50px;
  height: 50px;
  background: #fff;
  text-align: center;
  line-height: 50px;
  margin-right: 16px;
}
.car-info-box .car-img img {
  max-width: 100%;
  max-height: 100%;
}
.car-info-box .car-info-wrap {
  flex: 1 1;
}
.car-info-box .car-info-wrap .title {
  font-size: 16px;
  color: #000;
  color: var(--font-title);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 8px;
}
.car-info-box .car-info-wrap .car-list {
  margin-bottom: 8px;
}
.car-info-box .car-info-wrap .car-list .car-item {
  display: inline-block;
  vertical-align: middle;
  min-width: 160px;
  padding: 0 1em;
  height: 24px;
  margin-right: 12px;
  text-align: center;
  line-height: 24px;
  border-radius: 2px;
  font-size: 12px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  color: #000;
  color: var(--font-title);
  cursor: pointer;
}
.car-info-box .car-info-wrap .car-list .car-item:hover, .car-info-box .car-info-wrap .car-list .car-item.active {
  border-color: #4754ff;
  border-color: var(--color);
  color: #4754ff;
  color: var(--color);
}

.inquire-body-wrap {
  min-height: 600px;
}

.parts-box-wrap {
  margin-top: 32px;
}
.parts-box-wrap .title {
  padding-bottom: 6px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  color: var(--font-title);
  display: flex;
  align-items: center;
}
.parts-box-wrap .title:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 2px;
  height: 14px;
  background: #000;
  background: var(--font-title);
  margin-right: 6px;
}
.parts-box-wrap .parts-box-main {
  padding-top: 16px;
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 342px;
  position: relative;
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area {
  display: flex;
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .txt {
  flex: 1 1;
  height: 32px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  border-radius: 4px 0 0 4px;
  padding-left: 1em;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .search-btn {
  display: inline-block;
  width: 52px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background: #4754ff;
  background: var(--color);
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .search-btn:hover {
  opacity: 0.8;
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  height: 343px;
  background: #fff;
  box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(240, 240, 240);
  padding: 0 14px;
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap .spin-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap li {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  line-height: 32px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  cursor: pointer;
}
.parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap li:hover {
  color: #4754ff;
  color: var(--color);
}
.parts-box-wrap .parts-box-main .search-box-wrap .epc-btn {
  margin-left: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #4754ff;
  color: var(--color);
  cursor: pointer;
}
.parts-box-wrap .parts-box-main .search-box-wrap .epc-btn .iconfont {
  font-size: 18px;
}

.parts-result-box {
  margin-top: 24px;
}
.parts-result-box table {
  width: 100%;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  table-layout: fixed;
  margin-bottom: 16px;
}
.parts-result-box th {
  height: 40px;
  line-height: 40px;
  background: #f5f5f5;
  font-weight: normal;
  padding: 0 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  color: #666;
  color: var(--font-normal);
}
.parts-result-box th:nth-child(2), .parts-result-box th:nth-child(3) {
  width: 15%;
}
.parts-result-box th .price {
  color: #4754ff;
  color: var(--font-high);
}
.parts-result-box th .price:hover {
  color: #4754ff;
  color: var(--font-high);
}
.parts-result-box th .epc-btn {
  cursor: pointer;
  float: left;
}
.parts-result-box th .epc-btn .svg-icon {
  width: 30px;
  height: 30px;
}
.parts-result-box th span {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
.parts-result-box th span:hover {
  color: #4754ff;
  color: var(--color);
}
.parts-result-box th span .iconfont {
  font-size: 12px;
}
.parts-result-box th:last-child {
  text-align: right;
}
.parts-result-box td {
  padding: 12px 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  color: #4754ff;
  color: var(--font-high);
}
.parts-result-box td p {
  margin-bottom: 0;
}
.parts-result-box td .tips {
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}
.parts-result-box td .info-box .tit {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  margin-bottom: 1em;
}
.parts-result-box td .info-box .tips {
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}
.parts-result-box td .info-box .tips span {
  display: inline-block;
  vertical-align: top;
  margin-right: 1em;
}
.parts-result-box td .info-box .tips span i {
  color: #4754ff;
  color: var(--font-high);
}
.parts-result-box td .price {
  display: inline-block;
  margin-right: 8px;
  font-size: 16px;
}
.parts-result-box td .cost {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
  text-decoration: line-through;
}
.parts-result-box td .cart {
  cursor: pointer;
}
.parts-result-box td:last-child {
  text-align: right;
}

.ys-drawer-box .ant-drawer-content-wrapper:before {
  content: "·原厂数据·";
  display: inline-block;
  width: 144px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 50px 0 0 50px;
  position: absolute;
  top: 0;
  left: -144px;
}
.ys-drawer-box .ant-drawer-content-wrapper .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.ys-drawer-box .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body {
  flex: 1 1;
  height: 0;
  padding: 0;
}
.ys-drawer-box .epc-data-box {
  height: 100%;
}
.ys-drawer-box .epc-data-box .ant-layout {
  height: 100%;
  background: #fff;
  overflow: hidden;
  padding: 8px;
}
.ys-drawer-box .epc-data-box .ant-layout .ant-layout {
  padding: 0;
}
.ys-drawer-box .epc-data-box .ant-layout-sider {
  background: #f8f8f8;
  overflow: hidden;
  width: 160px;
}
.ys-drawer-box .epc-data-box .ant-layout-sider .label-box {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  cursor: pointer;
}
.ys-drawer-box .epc-data-box .ant-layout-sider .label-box:hover, .ys-drawer-box .epc-data-box .ant-layout-sider .label-box.active {
  background: #4754ff;
  background: var(--color);
  color: #fff;
}
.ys-drawer-box .epc-data-box .sider-body-box {
  height: 100%;
}
.ys-drawer-box .epc-data-box .ant-spin-nested-loading {
  height: 100%;
}
.ys-drawer-box .epc-data-box .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.ys-drawer-box .structure-chart-box {
  padding: 16px 0 16px 16px;
  height: 100%;
}
.ys-drawer-box .structure-chart-card {
  display: inline-block;
  vertical-align: top;
  width: 198px;
  margin-right: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px 0 rgba(153, 153, 153, 0.2);
  background: #fff;
  cursor: pointer;
}
.ys-drawer-box .structure-chart-card:nth-child(3n) {
  margin-right: 0;
}
.ys-drawer-box .structure-chart-card .img-box {
  width: 100%;
  height: 198px;
  text-align: center;
  line-height: 198px;
}
.ys-drawer-box .structure-chart-card .img-box img {
  max-height: 100%;
  max-width: 100%;
}
.ys-drawer-box .structure-chart-card .title {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  text-align: center;
}
.ys-drawer-box .structure-chart-detail-box {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head {
  padding: 8px;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head .ant-breadcrumb span:first-child .ant-breadcrumb-link {
  cursor: pointer;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head .ant-breadcrumb span:first-child .ant-breadcrumb-link:hover {
  color: #4754ff;
  color: var(--color);
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 0;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box {
  width: 100%;
  text-align: center;
  position: relative;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box {
  display: inline-block;
  width: 400px;
  height: 400px;
  line-height: 400px;
  text-align: center;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box {
  font-size: 12px;
  color: #666;
  color: var(--font-normal);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(216px);
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box .txt {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box button {
  display: inline-block;
  vertical-align: middle;
  border: 0 none;
  width: auto;
  background: transparent;
  cursor: pointer;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box button:hover {
  color: #4754ff;
  color: var(--color);
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-title {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  line-height: 1em;
  margin: 16px 0 8px;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-title:before {
  content: "";
  display: inline-block;
  margin: 0 4px 0 14px;
  width: 2px;
  height: 14px;
  background: #000;
  background: var(--font-title);
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content {
  flex: 1 1;
  height: 0;
  position: relative;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .table-head {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 40px;
  background: #fcfcfc;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  z-index: 3;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .table-head .head-item {
  padding-left: 24px;
  width: 16.6666666667%;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table {
  width: 100%;
  table-layout: fixed;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table th {
  height: 40px;
  background: #fcfcfc;
  opacity: 0;
  width: 16.6666666667%;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td {
  height: 50px;
  padding-left: 24px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  vertical-align: middle;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .txt {
  display: inline-block;
  width: 100%;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .btn {
  display: inline-block;
  width: 76px;
  height: 22px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  background: #fff;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #4754ff;
  color: var(--color);
  cursor: pointer;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .btn:hover {
  opacity: 0.8;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn {
  display: inline-block;
  width: 78px;
  height: 24px;
  background: #4754ff;
  background: var(--color);
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn:before {
  content: "已加入";
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn:hover:before {
  content: "取消加入";
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer {
  height: 50px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  padding-left: 24px;
  line-height: 50px;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .tips-box {
  display: inline-block;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 30px;
  background: #4754ff;
  background: var(--color);
  font-size: 16px;
  color: #fff;
  border: 0 none;
}
.ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .btn:hover {
  opacity: 0.8;
}

.epc-detail-box {
  width: 100%;
}
.epc-detail-box .img-box-wrap {
  width: 100%;
  text-align: center;
}
.epc-detail-box .img-box-wrap .img-box {
  display: inline-block;
  width: 400px;
  height: 400px;
  text-align: center;
  line-height: 400px;
  overflow: hidden;
}
.epc-detail-box .img-box-wrap .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.epc-detail-box .info-box {
  margin-top: 10px;
}
.epc-detail-box .info-box .descriptions-box {
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.epc-detail-box .info-box .descriptions-box .descriptions-item-box {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  margin: 0;
  padding: 0;
}
.epc-detail-box .info-box .descriptions-box .descriptions-item-box:last-child {
  border-bottom: 0 none;
}
.epc-detail-box .info-box .descriptions-box .descriptions-item-box .descriptions-title {
  width: 90px;
  border-right: 1px solid #DDDDDD;
  border-right: 1px solid var(--border-deep);
  margin: 0;
  padding-left: 1em;
}
.epc-detail-box .info-box .descriptions-box .descriptions-item-box .descriptions-main {
  padding-left: 1em;
}

.inquire-foot-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #f2f2f2;
  background: #fcfcfc;
}
.inquire-foot-wrap .btn-group {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.inquire-foot-wrap .btn-group .btn {
  border: 0 none;
  width: 150px;
  height: 100%;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background: #4754ff;
  background: var(--color);
}
.inquire-foot-wrap .btn-group .btn[disabled] {
  background: #b3b3b3;
  background: var(--disabled);
  color: #fff;
}
.inquire-foot-wrap .btn-group .btn.minor {
  color: #4754ff;
  color: var(--color);
  background: rgba(71, 84, 255, .1);
  background: var(--color-hover);
}
.inquire-foot-wrap .btn-group .btn:hover {
  opacity: 0.8;
}

.inquiry-list-page-box {
  height: 100%;
  background: #fff;
  margin-top: 14px;
}

.inquiry-list-page-main {
  padding: 16px;
}
.inquiry-list-page-main .inquiry-list-page-main-head {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab {
  display: inline-block;
  vertical-align: middle;
  margin: 0 14px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab:hover {
  color: #4754ff;
  color: var(--color);
}
.inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab.active {
  color: #4754ff;
  color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
}
.inquiry-list-page-main .inquiry-list-page-main-head .search-box .input-wrap, .inquiry-list-page-main .inquiry-list-page-main-head .search-box .select-box {
  display: inline-block;
  vertical-align: middle;
}
.inquiry-list-page-main .inquiry-list-page-main-head .search-box .input-wrap {
  margin-right: 8px;
}
.inquiry-list-page-main .inquiry-list-page-main-body {
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card {
  padding: 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  font-size: 14px;
  display: flex;
  cursor: pointer;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card:hover {
  background: rgba(71, 84, 255, .1);
  background: var(--color-hover);
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card:last-child {
  border-bottom: 0 none;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .img-box {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box {
  flex: 1 1;
  width: 0;
  padding: 0 16px;
  color: #999;
  color: var(--font-tips);
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title {
  margin-bottom: 8px;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt-title {
  color: #000;
  color: var(--font-title);
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt-title .tips {
  margin-left: 1em;
  color: #999;
  color: var(--font-tips);
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt {
  margin-left: 1em;
  color: #666;
  color: var(--font-normal);
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .tips-box .txt {
  margin-left: 1em;
  color: #999;
  color: var(--font-tips);
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box {
  margin-top: 8px;
  border-top: 1px solid #DDDDDD;
  border-top: 1px solid var(--border-deep);
  padding-top: 8px;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .tit {
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
  margin-bottom: 4px;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .remark-box {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  line-height: 1.5;
  margin-bottom: 4px;
  word-break: break-all;
  white-space: normal;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .attachment-main .img-box {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  margin-right: 8px;
}
.inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .attachment-main .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.inquiry-list-page-main .inquiry-list-page-main-foot {
  margin-top: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.inquiry-detail-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%;
}

.inquiry-detail-page-main {
  padding: 16px;
}
.inquiry-detail-page-main .car-info-box {
  align-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin-top: 0;
  margin-bottom: 16px;
  padding: 8px 16px;
}
.inquiry-detail-page-main .car-info-box:before {
  display: none;
}
.inquiry-detail-page-main .car-info-box .img-box {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
}
.inquiry-detail-page-main .car-info-box .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.inquiry-detail-page-main .car-info-box .info-box {
  flex: 1 1;
  padding-left: 24px;
  color: #999;
  color: var(--font-tips);
}
.inquiry-detail-page-main .car-info-box .info-box span {
  margin-right: 16px;
}
.inquiry-detail-page-main .car-info-box .info-box span:first-child {
  color: #000;
  color: var(--font-title);
}
.inquiry-detail-page-main .car-info-box .text-btn {
  color: #000;
  color: var(--font-title);
  cursor: pointer;
}
.inquiry-detail-page-main .car-info-box .text-btn:hover {
  color: #4754ff;
  color: var(--color);
}
.inquiry-detail-page-main .detail-main-box {
  display: flex;
}
.inquiry-detail-page-main .detail-main-box .left-box {
  width: 200px;
  min-height: 694px;
  margin-right: 16px;
  background: #f8f8f8;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.inquiry-detail-page-main .detail-main-box .left-box li {
  width: 100%;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 40px;
  font-size: 14px;
  padding: 0 16px;
  color: #000;
  color: var(--font-title);
  cursor: pointer;
}
.inquiry-detail-page-main .detail-main-box .left-box li:hover, .inquiry-detail-page-main .detail-main-box .left-box li.active {
  color: #4754ff;
  color: var(--color);
  background: #fff;
}
.inquiry-detail-page-main .detail-main-box .right-box {
  flex: 1 1;
  width: 0;
}
.inquiry-detail-page-main .inquiry-product-list-box {
  margin-bottom: 16px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head {
  background: #f8f8f8;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  padding: 0 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head span {
  display: inline-block;
  margin-right: 32px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .epc-btn {
  float: right;
  line-height: 1;
  cursor: pointer;
  margin-right: 0;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .epc-btn .svg-icon {
  width: 36px;
  height: 36px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main {
  min-height: 220px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  font-size: 14px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box {
  flex: 1 1;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tit {
  color: #000;
  color: var(--font-title);
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tips span {
  margin-right: 8px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tips .iconfont {
  font-size: 14px;
  color: #4754ff;
  color: var(--font-high);
  margin-right: 4px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .price-box {
  width: 20%;
  color: #4754ff;
  color: var(--font-high);
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart {
  width: 10%;
  text-align: right;
  cursor: pointer;
  color: #000;
  color: var(--font-title);
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart.active {
  color: #4754ff;
  color: var(--font-high);
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart .iconfont {
  font-size: 14px;
  margin-right: 4px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box {
  text-align: center;
  padding-top: 40px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap {
  display: inline-block;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .icon-box .svg-icon {
  width: 90px;
  height: 90px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .tips-text {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .status-box {
  width: 102px;
  height: 24px;
  border: 1px solid #999;
  border: 1px solid var(--font-tips);
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  border-radius: 4px;
  margin-top: 8px;
}
.inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .time {
  margin-top: 8px;
  font-size: 14px;
  color: #4754ff;
  color: var(--font-high);
}
.inquiry-detail-page-main .parts-remark-box {
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.inquiry-detail-page-main .parts-remark-box .parts-remark-box-head {
  background: #f8f8f8;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  padding: 0 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.inquiry-detail-page-main .parts-remark-box .parts-remark-box-body {
  min-height: 200px;
  padding: 16px;
}
.inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .remark-wrap {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  margin-bottom: 8px;
  word-break: break-all;
  white-space: normal;
}
.inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .img-wrap .img-box {
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  width: 144px;
  height: 144px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  text-align: center;
  line-height: 144px;
}
.inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .img-wrap .img-box img {
  max-height: 100%;
  max-width: 100%;
}

.remark-pop-body .text-area-wrap {
  margin-bottom: 16px;
}

@media all and (max-height: 768px) {
  .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box {
    width: 240px;
    height: 240px;
    line-height: 240px;
  }
}
.goods-list-wrap {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  background: #fff;
}
.goods-list-wrap .ant-pagination {
  padding: 32px 0 8px;
  text-align: center;
}
.goods-list-wrap .ant-spin {
  display: block;
  padding: 48px 0 36px;
}
.goods-list-wrap .ant-empty {
  padding: 48px 0 36px;
}

.goods-list-box {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.goods-list-card {
  width: 300px;
  height: 406px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
}
.goods-list-card:hover {
  border-color: #4754ff;
  border-color: var(--color);
  z-index: 1;
}
.goods-list-card:not(:nth-child(4n)) {
  margin-right: -1px;
}
.goods-list-card:nth-child(n+5) {
  margin-top: -1px;
}
.goods-list-card .img-box {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}
.goods-list-card .img-box img {
  max-width: 158px;
  max-height: 158px;
  -o-object-fit: cover;
     object-fit: cover;
}
.goods-list-card .tag-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  padding: 16px 12px 10px;
  min-width: 100%;
}
.goods-list-card .tag {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  margin: 0 8px 4px 0;
  border-radius: 8px;
  padding: 0 12px;
  display: inline-block;
  color: white;
}
.goods-list-card .tag:nth-child(n+4) {
  display: none;
}
.goods-list-card .goods-info-box {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
}
.goods-list-card .goods-info-box .goods-info-row {
  flex: 1 1;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  line-height: 1.4;
}
.goods-list-card .goods-info-box .goods-info-row:not(:last-child) {
  margin-bottom: 8px;
}
.goods-list-card .goods-info-box .title-row {
  min-height: 38px;
  height: 38px;
  color: #000;
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 600;
}
.goods-list-card .goods-info-box .price-row {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
.goods-list-card .goods-info-box .price-row i {
  margin-right: 4px;
}
.goods-list-card .goods-info-box .price-row span {
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
  text-decoration: line-through;
  display: inline-block;
  padding-left: 8px;
}
.goods-list-card .goods-info-box .addToCart {
  position: absolute;
  transform: translate(170px, 42px);
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  color: #4754ff;
  color: var(--color);
  transition: all 0.5s;
}
.goods-list-card .goods-info-box .addToCart:hover {
  cursor: pointer;
  color: white;
  background: #4754ff;
  background: var(--color);
}

.goods-card-cover {
  text-align: center;
}
.goods-card-cover .goods-list-card {
  display: inline-block;
  margin-right: 0;
  margin-bottom: 0;
  text-align: left;
}

.goods-list-row {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.goods-list-row .check-box {
  width: 20px;
  margin-right: 10px;
  align-self: center;
  cursor: pointer;
}
.goods-list-row .check-box:hover {
  color: #4754ff;
  color: var(--color);
}
.goods-list-row .check-box .icon-check-s {
  color: #4754ff;
  color: var(--color);
}
.goods-list-row .image-box {
  width: 120px;
  height: 120px;
  position: relative;
  cursor: pointer;
  text-align: center;
  line-height: 120px;
}
.goods-list-row .image-box img {
  max-height: 100%;
  max-width: 100%;
}
.goods-list-row .image-box .tips-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 32px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
}
.goods-list-row .detail-box {
  width: 348px;
  padding-left: 24px;
  word-break: break-all;
  cursor: pointer;
}
.goods-list-row .detail-box .name {
  font-size: 14px;
}
.goods-list-row .detail-box .tips {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}
.goods-list-row .describe-box {
  width: 336px;
  padding-left: 24px;
}
.goods-list-row .describe-box .price {
  font-size: 16px;
  color: #4754ff;
  color: var(--font-high);
}
.goods-list-row .describe-box .unit {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}
.goods-list-row .cart-box {
  width: 246px;
  padding-left: 24px;
}
.goods-list-row .cart-box .stock {
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}
.goods-list-row .cart-box .label {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  padding: 0 8px;
  color: #000;
  color: var(--font-title);
  border-radius: 12px;
  background: #F5F5F5;
}
.goods-list-row .operate-box {
  align-self: center;
}
.goods-list-row .operate-box .btn {
  width: 102px;
  line-height: 32px;
  font-size: 14px;
  border: 0 none;
  border-radius: 4px;
  background: #4754ff;
  background: var(--color);
  color: #fff;
  cursor: pointer;
}
.goods-list-row .operate-box .btn:hover {
  opacity: 0.8;
}
.goods-list-row .operate-box .btn:disabled {
  background: #b3b3b3;
  background: var(--disabled);
}

.goods-detail-head {
  width: 1200px;
  display: flex;
  padding: 20px 16px;
  font-family: PingFang SC;
}
.goods-detail-head .goods-detail-box {
  flex: 1 1;
  padding-left: 32px;
  width: 0;
}
.goods-detail-head .goods-detail-box .name {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  color: var(--font-title);
  word-break: break-all;
  white-space: normal;
}
.goods-detail-head .goods-detail-box .timeRange {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-weight: 400;
}
.goods-detail-head .goods-detail-box .code {
  color: #999;
  color: var(--font-tips);
}
.goods-detail-head .goods-detail-box .code .item {
  display: inline-block;
  vertical-align: top;
  word-break: break-word;
  margin-right: 24px;
}
.goods-detail-head .goods-detail-box .code .icon-shuoming {
  margin-right: 2px;
}
.goods-detail-head .coupon-box {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  border: 1px solid #DCE0E5;
}
.goods-detail-head .coupon-box .label {
  padding-right: 8px;
}
.goods-detail-head .coupon-box .more-btn {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.goods-detail-head .coupon-box .icon-jump {
  font-size: 12px;
}
.goods-detail-head .coupon-box .coupon-list-box {
  flex: 1 1;
  overflow: hidden;
  height: 24px;
}
.goods-detail-head .coupon-box .coupon-label {
  align-items: center;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  line-height: 18px;
  color: #F43005;
  color: var(--orangey-red);
  position: relative;
  margin: 0 4px 12px 2px;
  padding: 0 8px;
  border-radius: 2px;
  border: 1px solid #F43005;
  border: 1px solid var(--orangey-red);
}
.goods-detail-head .price-wrap-row {
  padding: 0 12px;
  background-repeat: repeat-x;
  background-position: bottom left;
  margin: 10px 0 -1px;
  border: 1px solid #DCE0E5;
  background: #F7F8FA;
}
.goods-detail-head .price-wrap-row .price-row {
  display: flex;
  align-content: center;
  align-items: center;
  margin: 8px 0;
}
.goods-detail-head .price-wrap-row .price-row .title {
  min-width: 60px;
  color: #666;
  color: var(--font-normal);
  font-size: 14px;
}
.goods-detail-head .price-wrap-row .price-row .price {
  flex: 1 1;
  font-size: 14px;
  color: #F43005;
  color: var(--orangey-red);
}
.goods-detail-head .price-wrap-row .price-row .price .hint {
  font-size: 24px;
  font-weight: 600;
  color: #F43005;
  color: var(--orangey-red);
}
.goods-detail-head .price-wrap-row .price-row .price .line-through {
  text-decoration: line-through;
}
.goods-detail-head .price-wrap-row .market-price {
  color: #999;
  color: var(--font-tips);
}
.goods-detail-head .price-wrap-row .market-price .line-through {
  text-decoration: line-through;
}
.goods-detail-head .discoumt-wrap-row {
  height: 42px;
  border: 1px solid #DCE0E5;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 20px;
  margin-bottom: 12px;
}
.goods-detail-head .discoumt-wrap-row .name {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  width: 52px;
}
.goods-detail-head .discoumt-wrap-row .discount-price-wrap {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  transform: translateY(-3px);
  display: flex;
  align-items: center;
}
.goods-detail-head .discoumt-wrap-row .customTag {
  font-size: 12px;
  line-height: 16px;
  height: 18px;
  border: 1px solid #F43005;
  border: 1px solid var(--orangey-red);
  color: #F43005;
  color: var(--orangey-red);
  min-width: 32px;
  text-align: center;
  margin-right: 8px;
}
.goods-detail-head .product-wrap {
  display: flex;
  padding: 0 12px;
  margin: 16px 0;
}
.goods-detail-head .product-wrap .title {
  width: 52px;
  font-size: 14px;
  text-align: left;
  color: #666;
  color: var(--font-normal);
  height: 40px;
  line-height: 40px;
}
.goods-detail-head .product-wrap .product-main {
  flex: 1 1;
}
.goods-detail-head .product-wrap .product-main .product-item {
  display: inline-block;
  position: relative;
  padding: 6px 12px;
  min-width: 60px;
  line-height: 16px;
  margin-bottom: 8px;
  margin-right: 8px;
  text-align: center;
  border: 1px solid #b3b3b3;
  cursor: pointer;
  color: #000;
  color: var(--font-title);
  background: #fff;
  max-width: 300px;
  border-radius: 4px;
  border: 0.5px solid #DCE0E5;
  background: #F7F8FA;
}
.goods-detail-head .product-wrap .product-main .product-item:not(:disabled):hover, .goods-detail-head .product-wrap .product-main .product-item:not(:disabled).active {
  border-color: #4754ff;
  border-color: var(--color);
  color: #4754ff;
  color: var(--color);
  background: rgba(70, 96, 255, 0.1);
}
.goods-detail-head .product-wrap .product-main .product-item:disabled {
  color: #fff;
  border: 1px solid #b3b3b3;
  border: 1px solid var(--disabled);
  background: #b3b3b3;
  background: var(--disabled);
  cursor: not-allowed;
}
.goods-detail-head .promotion-row {
  display: flex;
  padding: 16px 20px;
  border: 1px solid #DCE0E5;
  margin-top: -1px;
  max-height: 52px;
  overflow: hidden;
  transition: all 0.3s;
}
.goods-detail-head .promotion-row.showProduct {
  max-height: 500px;
}
.goods-detail-head .promotion-row .title {
  align-self: flex-start;
  min-width: 52px;
}
.goods-detail-head .promotion-row .price {
  display: flex;
  flex: 1 1;
}
.goods-detail-head .promotion-row .price .more {
  cursor: pointer;
  white-space: nowrap;
  color: #999;
  color: var(--font-tips);
  font-size: 12px;
}
.goods-detail-head .promotion-row .price .more:hover {
  color: #4754ff;
  color: var(--color);
}
.goods-detail-head .promotion-row .price .info-box {
  flex: 1 1;
}
.goods-detail-head .promotion-row .customTag {
  font-size: 12px;
  line-height: 16px;
  height: 18px;
  border: 1px solid #F43005;
  border: 1px solid var(--orangey-red);
  color: #F43005;
  color: var(--orangey-red);
  min-width: 32px;
  text-align: center;
  margin-right: 8px;
}
.goods-detail-head .promotion-row .high {
  color: #4754ff;
  color: var(--font-high);
}
.goods-detail-head .promotion-row .promotion-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 4px;
  font-size: 14px;
}
.goods-detail-head .promotion-row .promotion-item .img-box-wrap {
  margin-top: 12px;
}
.goods-detail-head .promotion-row .promotion-item .img-box {
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 48px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  overflow: hidden;
  margin: 0 4px;
}
.goods-detail-head .promotion-row .promotion-item-finish-time {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 12px;
}
.goods-detail-head .productNumRow {
  display: flex;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  align-items: center;
}
.goods-detail-head .productNumRow .productNumRowTitle {
  min-width: 50px;
}
.goods-detail-head .productNumRow .rightBox {
  display: flex;
  align-items: center;
}
.goods-detail-head .productNumRow .productStock {
  display: flex;
  margin-left: 12px;
}
.goods-detail-head .productNumRow .productStock .limit-count {
  margin-left: 10px;
}
.goods-detail-head .productNumRow .numInputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goods-detail-head .productNumRow .numIconBox {
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background: #F7F8FA;
  cursor: pointer;
}
.goods-detail-head .productTotalPriceRow {
  display: flex;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  align-items: center;
  margin-top: 12px;
}
.goods-detail-head .productTotalPriceRow .productTotalPriceRowTitle {
  min-width: 50px;
}
.goods-detail-head .productTotalPriceRow .productTotalPrice {
  margin-left: 12px;
  color: #F43005;
  color: var(--orangey-red);
}
.goods-detail-head .product-info-wrap {
  position: relative;
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  margin-top: 42px;
}
.goods-detail-head .product-info-wrap .product-info-row {
  display: flex;
  align-content: center;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
}
.goods-detail-head .product-info-wrap .product-info-row .format-wrap {
  width: 28%;
  padding-right: 8px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.goods-detail-head .product-info-wrap .product-info-row .format-wrap:after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 50px;
  background: #DDDDDD;
  background: var(--border-deep);
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-27%);
}
.goods-detail-head .product-info-wrap .product-info-row .price-wrap {
  width: 20%;
  padding: 0 8px;
  color: #4754ff;
  color: var(--font-high);
}
.goods-detail-head .product-info-wrap .product-info-row .unit-wrap {
  flex: 1 1;
}
.goods-detail-head .product-info-wrap .product-info-row .stock-wrap {
  padding: 16px;
  max-width: 15%;
}
.goods-detail-head .product-info-wrap .product-info-row .quantity {
  margin-right: 10px;
}
.goods-detail-head .product-info-wrap .product-num-row {
  display: none;
  position: absolute;
  left: 0;
  bottom: 50px;
  background: #fafafa;
  width: 100%;
  height: 30px;
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: #DDDDDD;
  border-color: var(--border-deep);
  align-content: center;
  align-items: center;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  padding: 0 16px;
}
.goods-detail-head .product-info-wrap .product-num-row .number-total-wrap {
  width: 20%;
  border-right: 1px solid #DDDDDD;
  border-right: 1px solid var(--border-deep);
  padding-right: 12px;
  text-align: right;
}
.goods-detail-head .product-info-wrap .product-num-row .price-total-wrap {
  flex: 1 1;
  padding-left: 32px;
}
.goods-detail-head .product-info-wrap .product-num-row .detail-btn {
  cursor: pointer;
}
.goods-detail-head .product-info-wrap .product-num-row .detail-btn .iconfont {
  font-size: 12px;
  padding-left: 4px;
}
.goods-detail-head .product-info-wrap .cart-list-row {
  display: none;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #DDDDDD;
  border-color: var(--border-deep);
  position: absolute;
  width: 100%;
  background: #fff;
  bottom: 79px;
  left: 0;
  z-index: 4;
}
.goods-detail-head .product-info-wrap .cart-list-row .product-info-row {
  border: 0 none;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  background: #fff;
}
.goods-detail-head .btn-wrap {
  margin: 42px 0 16px;
}
.goods-detail-head .btn-wrap button {
  border-radius: 8px;
}
.goods-detail-head .btn-wrap .buy-btn {
  width: 150px;
  height: 40px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  font-size: 16px;
  font-weight: 600;
  color: #4754ff;
  color: var(--color);
  margin-right: 24px;
  cursor: pointer;
}
.goods-detail-head .btn-wrap .buy-btn:hover {
  opacity: 0.8;
}
.goods-detail-head .btn-wrap .disabled-btn {
  width: 150px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #b3b3b3;
  border: 1px solid var(--disabled);
  background: #b3b3b3;
  background: var(--disabled);
  margin-right: 24px;
  cursor: pointer;
}
.goods-detail-head .btn-wrap .add-btn {
  width: 150px;
  height: 40px;
  background-color: #4754ff;
  background-color: var(--color);
  border: 0 none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  margin-right: 32px;
}
.goods-detail-head .btn-wrap .add-btn:hover {
  opacity: 0.8;
}
.goods-detail-head .btn-wrap .collect-btn {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.goods-detail-head .btn-wrap .collect-btn .iconfont {
  margin-right: 6px;
}
.goods-detail-head .btn-wrap .collect-btn:hover, .goods-detail-head .btn-wrap .collect-btn.active {
  color: #4754ff;
  color: var(--color);
}

.goods-package-detail-head .price-wrap-row .price-row .item {
  display: flex;
  align-items: center;
  flex: 1 1;
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(2) .title {
  width: 80px;
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast {
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  white-space: nowrap;
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast .fb {
  font-weight: 700;
  font-size: 15px;
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast .day {
  display: inline-block;
  padding: 0 6px 0 16px;
  color: #4754ff;
  color: var(--color-hint);
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", SimSun, sans-serif;
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast .time {
  display: inline-block;
  font-size: 10px;
  line-height: 28px;
  min-width: 28px;
  margin: 0 5px;
  text-align: center;
  background: #4754ff;
  background: var(--color-hint);
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", SimSun, sans-serif;
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.disabled {
  color: #999;
  color: var(--font-tips);
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.disabled .day {
  color: #999;
  color: var(--font-tips);
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.disabled .time {
  background: #D2D2D2;
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.invalid {
  color: #000;
  color: var(--font-title);
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.invalid .day {
  color: #000;
  color: var(--font-title);
}
.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.invalid .time {
  background: #333;
}

.package-list-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  height: 212px;
  overflow: auto;
}
.package-list-box .goods-item-cart {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  height: 100px;
  border: 0.5px solid #DCE0E5;
  background: #F7F8FA;
  border-radius: 8px;
}
.package-list-box .goods-item-cart .img-box {
  width: 76px;
  height: 76px;
  line-height: 76px;
  text-align: center;
  overflow: hidden;
}
.package-list-box .goods-item-cart .img-box img {
  -o-object-fit: cover;
     object-fit: cover;
}
.package-list-box .goods-item-cart .info-box {
  padding-left: 8px;
  line-height: 1;
  font-size: 14px;
  flex: 1 1;
}
.package-list-box .goods-item-cart .info-box p {
  margin-bottom: 8px;
}
.package-list-box .goods-item-cart .info-box p:last-child {
  margin-bottom: 0;
}
.package-list-box .goods-item-cart .info-box .tit {
  font-weight: 600;
  color: #000;
  color: var(--font-title);
}
.package-list-box .goods-item-cart .info-box .format {
  color: #666;
  color: var(--font-normal);
}
.package-list-box .goods-item-cart .info-box .price {
  color: #999;
  color: var(--font-tips);
}
.package-list-box .goods-item-cart .info-box .price i {
  font-size: 16px;
  color: #FA3815;
  font-weight: 600;
}

.video-pop-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
}
.video-pop-box:hover .video-close, .video-pop-box:hover .video-play-box, .video-pop-box:hover .video-pause-box {
  display: block;
}
.video-pop-box .video-close {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  text-align: right;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.video-pop-box .video-close i {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.video-pop-box .time-display-box {
  position: absolute;
  left: 24px;
  bottom: 24px;
  font-size: 14px;
  color: #fff;
  z-index: 3;
  padding: 4px 8px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.4);
}
.video-pop-box .video-play-box, .video-pop-box .video-pause-box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.video-pop-box .video-play-box img, .video-pop-box .video-pause-box img {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-video-btn {
  position: absolute;
  left: 16px;
  bottom: 40px;
  width: 90px;
  height: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 100px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.goods-product-box {
  margin-top: 32px;
}

.goods-product-item {
  display: flex;
  margin-bottom: 32px;
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}
.goods-product-item .detail-box {
  width: 340px;
  padding-right: 16px;
}
.goods-product-item .detail-box .format {
  line-height: 24px;
  color: #666;
  color: var(--font-normal);
}
.goods-product-item .detail-box .format .code {
  color: #999;
  color: var(--font-tips);
}
.goods-product-item .detail-box .icon-spec-picture {
  color: #999;
  color: var(--font-tips);
  margin-right: 4px;
  font-size: 14px;
}
.goods-product-item .detail-box .icon-spec-picture:hover {
  opacity: 0.8;
  cursor: pointer;
}
.goods-product-item .detail-box .iconimg {
  color: #000;
  color: var(--font-title);
}
.goods-product-item .describe-box {
  margin-top: 8px;
}
.goods-product-item .describe-box .row {
  padding-right: 24px;
}
.goods-product-item .describe-box .iconfont {
  color: #4754ff;
  color: var(--font-high);
}
.goods-product-item .price-box {
  width: 120px;
}
.goods-product-item .price-box .price {
  font-size: 16px;
  color: #4754ff;
  color: var(--font-high);
}
.goods-product-item .price-box .price .unit {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}
.goods-product-item .uint-box {
  margin-top: 8px;
}
.goods-product-item .cart-box {
  width: 126px;
}
.goods-product-item .cart-box .stock {
  margin-top: 8px;
}
.goods-product-item .operate-box {
  align-self: flex-end;
}
.goods-product-item .operate-box .btn {
  width: 102px;
  line-height: 32px;
  font-size: 14px;
  border: 0 none;
  border-radius: 4px;
  background: #4754ff;
  background: var(--color);
  color: #fff;
  cursor: pointer;
}
.goods-product-item .operate-box .btn:hover {
  opacity: 0.8;
}
.goods-product-item .operate-box .btn:disabled {
  background: #b3b3b3;
  background: var(--disabled);
}

.goods-detail-introduce {
  width: 800px;
  margin: 24px auto 0;
  word-break: break-all;
  white-space: normal;
}
.goods-detail-introduce img {
  min-width: 100%;
}

.goods-detail-property {
  flex-wrap: wrap;
  padding: 0 16px;
}
.goods-detail-property .item {
  padding-right: 120px;
  vertical-align: top;
  font-size: 14px;
  margin-bottom: 8px;
  color: #666;
  color: var(--font-normal);
}

.goods-detail-tabs .ant-tabs-nav-container {
  height: 54px;
  padding-top: 8px;
  padding-left: 16px;
  background: #F5F5F5;
}
.goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab:hover, .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab:active {
  color: #4754ff;
  color: var(--color);
}
.goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab-active {
  color: #4754ff;
  color: var(--color);
}
.goods-detail-tabs .ant-tabs-nav-container .ant-tabs-ink-bar {
  background-color: #4754ff;
  background-color: var(--color);
}
.goods-detail-tabs .ant-pagination {
  margin: 62px auto 24px;
  text-align: center;
}
.goods-detail-tabs .ant-empty {
  margin: 48px 0 24px;
}

.recommend-list-box {
  margin-bottom: 40px;
}
.recommend-list-box .ant-carousel .slick-dots-bottom {
  bottom: -18px;
  margin-bottom: 0;
}
.recommend-list-box .ant-carousel .slick-dots li {
  height: 8px;
  width: auto;
}
.recommend-list-box .ant-carousel .slick-dots li button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #666;
}
.recommend-list-box .ant-carousel .slick-dots li.slick-active {
  width: auto;
}
.recommend-list-box .ant-carousel .slick-dots li.slick-active button {
  background: red;
}

.goods-brand-page-main {
  background: #fff;
  min-height: 600px;
  padding: 16px;
}
.goods-brand-page-main .first-letter-wrap .item {
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
}
.goods-brand-page-main .first-letter-wrap .item:hover {
  color: #4754ff;
  color: var(--color);
}
.goods-brand-page-main .first-letter-wrap .item.active {
  border-color: #4754ff;
  border-color: var(--color);
  color: #4754ff;
  color: var(--color);
}
.goods-brand-page-main .brand-list-wrap {
  position: relative;
  display: block;
  transition: all 0.4s ease-out;
  overflow: hidden;
  max-width: 100%;
}
.goods-brand-page-main .brand-list-wrap .brand-card {
  position: absolute;
  display: inline-block;
  vertical-align: top;
  width: 222px;
  height: 116px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  transition: all 0.4s ease-out;
  overflow: hidden;
}
.goods-brand-page-main .brand-list-wrap .brand-card:hover .brand-hover {
  opacity: 1;
  transition: opacity 0.3s linear;
}
.goods-brand-page-main .brand-list-wrap .brand-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.goods-brand-page-main .brand-list-wrap .brand-main span {
  font-size: 16px;
  line-height: 22px;
}
.goods-brand-page-main .brand-list-wrap .brand-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  opacity: 0;
  transition: opacity 0.3s linear;
  cursor: pointer;
}
.goods-brand-page-main .brand-list-wrap .brand-hover .box {
  text-align: center;
}
.goods-brand-page-main .brand-list-wrap .brand-hover .tit {
  font-size: 16px;
  line-height: 22px;
}
.goods-brand-page-main .brand-list-wrap .brand-hover .tips {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.goods-sn-search {
  padding: 0 48px;
  text-align: center;
  min-height: 540px;
}
.goods-sn-search .hint {
  width: 292px;
  text-align: center;
  margin: 0 auto;
  padding: 110px 0 26px;
  font-size: 20px;
  color: #999;
  color: var(--font-tips);
}
.goods-sn-search .search-status-wrap {
  text-align: center;
  padding: 30px 0 38px;
  margin: 0 auto 42px;
  width: 1088px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.goods-sn-search .search-status-wrap .svg-icon {
  width: 45px;
  height: 45px;
  margin-bottom: 16px;
}
.goods-sn-search .search-status-wrap .tips {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
}

.goods-sn-search-value .value {
  width: 638px;
  height: 58px;
  padding: 0 16px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 53px;
  background: #FFFFFF;
  border-radius: 37px;
  border: 1px solid #DCDCDD;
}

.goods-sn-search-btn {
  text-align: center;
  margin-bottom: 24px;
}
.goods-sn-search-btn .btn {
  width: 234px;
  height: 58px;
  line-height: 56px;
  font-size: 20px;
  border: 0 none;
  border-radius: 37px;
  background-color: #4754ff;
  background-color: var(--color);
  color: #fff;
}

.goods-sn-search-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 50px;
}
.goods-sn-search-product .img-box {
  width: 212px;
  height: 212px;
  line-height: 212px;
  margin-right: 12px;
  overflow: hidden;
  text-align: center;
}
.goods-sn-search-product .detail {
  flex: 1 1;
  padding: 0 42px;
  font-size: 14px;
  text-align: left;
  color: #999;
  color: var(--font-tips);
  line-height: 2;
}

.goods-package-list {
  margin-top: 16px;
  background: #fff;
  font-family: PingFang SC;
}
.goods-package-list .goods-package-list-item {
  border-radius: 8px;
  border: 0.5px solid #DCE0E5;
  background: #F7F8FA;
  padding: 16px 24px;
}
.goods-package-list:last-child {
  margin-bottom: 8px;
}
.goods-package-list .headline {
  font-size: 16px;
  color: #000;
  color: var(--font-title);
  display: flex;
  justify-content: space-between;
}
.goods-package-list .headline .name {
  font-weight: 600;
  white-space: nowrap;
}
.goods-package-list .headline .forecast {
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  white-space: nowrap;
}
.goods-package-list .headline .forecast .fb {
  font-weight: 700;
  font-size: 15px;
}
.goods-package-list .headline .forecast.disabled {
  color: #999;
  color: var(--font-tips);
}
.goods-package-list .headline .forecast.disabled .day {
  color: #999;
  color: var(--font-tips);
}
.goods-package-list .headline .forecast.disabled .time {
  background: #D2D2D2;
}
.goods-package-list .headline .forecast.invalid {
  color: #000;
  color: var(--font-title);
}
.goods-package-list .headline .forecast.invalid .day {
  color: #000;
  color: var(--font-title);
}
.goods-package-list .headline .forecast.invalid .time {
  background: #333;
}
.goods-package-list .headline .day {
  display: inline-block;
  padding: 0 6px 0 16px;
  color: #4754ff;
  color: var(--color-hint);
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", SimSun, sans-serif;
}
.goods-package-list .headline .time {
  display: inline-block;
  font-size: 10px;
  line-height: 28px;
  min-width: 28px;
  margin: 0 5px;
  text-align: center;
  background: #4754ff;
  background: var(--color-hint);
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", SimSun, sans-serif;
}
.goods-package-list .goods-package-cart-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.goods-package-list .goods-package-cart-main .img-box {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  text-align: center;
  line-height: 80px;
  overflow: hidden;
  border-radius: 8px;
  border: 0.8px solid #DCE0E5;
  background: #FFF;
}
.goods-package-list .goods-package-cart-main .img-box img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.goods-package-list .goods-package-cart-main .detail {
  color: rgba(0, 0, 0, 0.6);
}
.goods-package-list .goods-package-cart-main .cart-info {
  line-height: 1;
  padding: 20px 0 28px;
}
.goods-package-list .goods-package-cart-main .cart-info .tips {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
  margin-bottom: 10px;
}
.goods-package-list .goods-package-cart-main .cart-info .price-box {
  display: flex;
  justify-content: space-between;
}
.goods-package-list .goods-package-cart-main .cart-info .price-box .label {
  line-height: 48px;
  font-size: 14px;
}
.goods-package-list .goods-package-cart-main .cart-info .price-box .label .price {
  color: #4754ff;
  color: var(--color-hint);
}

.cheaper-price {
  display: inline-block;
  height: 22px;
  line-height: 18px;
  min-width: 60px;
  border: 1px solid #F43005;
  border-radius: 2px;
  overflow: hidden;
  color: #F43005;
  font-size: 14px;
  font-weight: 600;
}
.cheaper-price span {
  position: relative;
  display: inline-block;
  width: 20px;
  line-height: 16px;
  height: 16px;
  font-size: 12px;
  margin: 0 4px 0 2px;
  text-align: center;
  background: #f43005;
  color: #fff;
  overflow: hidden;
  transform: translateY(2px);
  border-radius: 2px;
}

.package-price-box {
  display: flex;
  font-size: 14px;
}
.package-price-box .flex-item {
  display: flex;
}
.package-price-box .flex-item:nth-child(1) {
  margin-right: 22px;
}
.package-price-box .flex-item:nth-child(1) .label {
  color: #F43005;
  color: var(--orangey-red);
}

.goods-package-list-wrap {
  width: 1200px;
  min-width: 1200px;
  margin: 10px auto;
}
.goods-package-list-wrap .ant-pagination {
  padding: 32px 0 8px;
  text-align: center;
}
.goods-package-list-wrap .ant-spin {
  display: block;
  padding: 48px 0 36px;
}
.goods-package-list-wrap .ant-empty {
  padding: 48px 0 36px;
}

.cart-page-wrap {
  width: 1200px;
  min-width: 1200px;
  min-height: 400px;
  padding: 12px 0 0;
  margin: 0 auto;
  background: #fff;
}
.cart-page-wrap .ant-spin {
  display: block;
  padding: 48px 0 36px;
}
.cart-page-wrap .ant-empty {
  padding: 48px 0 36px;
}

.cart-table .cart-table-head {
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  background: #F5F5F5;
}
.cart-table .cart-table-head .ant-checkbox-wrapper {
  padding: 0 10px;
}
.cart-table .cart-table-head .item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 10px;
}
.cart-table .cart-table-body:nth-of-type(1) .cart-table-body-head {
  display: none;
}
.cart-table .cart-table-body-order-promotion-box .order-promotion-box {
  font-size: 14px;
}
.cart-table .cart-table-body-order-promotion-box .order-promotion-box .tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  padding: 0 8px;
  height: 18px;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #FF6350;
  color: #FF6350;
  border-radius: 4px;
}
.cart-table .cart-table-body-order-promotion-box .order-promotion-box .text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
}
.cart-table .cart-table-body-order-promotion-box .order-promotion-box .tips {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  color: #FF6350;
}
.cart-table .cart-table-body-order-promotion-box .order-promotion-box .btn {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  padding: 0 8px;
  height: 20px;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  color: #666;
  color: var(--font-normal);
  border-radius: 4px;
  cursor: pointer;
}
.cart-table .cart-table-body-order-promotion-box .order-promotion-box .btn .iconfont {
  font-size: 12px;
  margin-left: 4px;
}
.cart-table .cart-table-body-head {
  line-height: 38px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  background-color: #FCFCFC;
  padding: 0 16px;
  border-top: none;
}
.cart-table .cart-table-body-head .col {
  margin: 0 12px;
}
.cart-table .cart-table-body-head .col .time,
.cart-table .cart-table-body-head .col .icon-goods-tag-delivery,
.cart-table .cart-table-body-head .col .icon-goods-tag-shipments {
  color: #4754ff;
  color: var(--font-high);
}
.cart-table .cart-table-body-head .ant-checkbox-wrapper {
  padding: 10px;
  margin-right: 10px;
}
.cart-table .cart-table-body-tr {
  border: 1px solid #E3E3E3;
  padding: 4px 0 6px;
  margin-top: -1px;
}
.cart-table .cart-table-body-tr .tr-expand {
  padding-left: 10px;
}
.cart-table .cart-table-body-tr .tr-expand .iconfont {
  color: #4754ff;
  color: var(--color);
  margin-right: 8px;
}
.cart-table .cart-table-body-tr .tr-expand .col {
  margin-right: 16px;
  color: #999;
  color: var(--font-tips);
}
.cart-table .cart-table-body-tr .tr-expand .remark-input {
  width: 390px;
}
.cart-table .cart-table-body-tr .tr-expand-check {
  padding-left: 46px;
}
.cart-table .cart-table-body-tr .tr-body {
  display: flex;
}
.cart-table .cart-table-body-tr .tr-body .ant-checkbox-wrapper {
  padding: 10px;
}
.cart-table .cart-table-body-tr .tr-body .check-hint {
  margin: 10px 0 0 6px;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  width: 46px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  background-color: #999;
}
.cart-table .cart-table-body-tr .tr-body .item {
  flex: 1 1 auto;
  padding: 10px;
  word-break: break-word;
}
.cart-table .cart-table-body-tr .tr-body .img-box {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 74px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  overflow: hidden;
}
.cart-table .cart-table-body-tr .tr-body .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.cart-table .cart-table-body-tr .tr-body .tips {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
  margin-top: 6px;
}
.cart-table .cart-table-body-tr .tr-body .price {
  color: #F43005;
  color: var(--orangey-red);
}
.cart-table .cart-table-body-tr .tr-body .productNumInput {
  margin: 0 6px;
}
.cart-table .cart-table-body-tr .tr-body .productNumIcon {
  width: 24px;
  height: 24px;
  line-height: 25px;
  color: #3E415C;
  border-radius: 4px;
  border: 0.5px solid #DCE0E5;
  background: #F7F8FA;
  text-align: center;
  display: inline-block;
  margin-top: -1px;
}
.cart-table .cart-table-body-tr .tr-body .productNumIcon:hover {
  color: #4660FF;
  border-color: #4660FF;
}
.cart-table .cart-table-body-tr .tr-body .text-btn {
  border: 0 none;
  cursor: pointer;
  color: #000;
  color: var(--font-title);
  background: transparent;
}

.cart-custom-modal .custom-hint-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.cart-custom-modal .custom-hint-info img {
  width: 200px;
  max-height: 160px;
}
.cart-custom-modal .custom-hint-info .ant-card-body {
  color: red;
}
.cart-custom-modal .form-item-hint {
  font-size: 12px;
  margin-left: 130px;
  padding-bottom: 20px;
  display: inherit;
  margin-top: -20px;
}
.cart-custom-modal .form-item-hint.red {
  color: red;
}
.cart-custom-modal .ant-drawer-footer {
  text-align: right;
}
.cart-custom-modal .ant-col-4 {
  flex: 0 0 20%;
  max-width: 20%;
}

.page-cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: -1px auto 0;
  height: 68px;
  padding: 12px 16px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  background: #FCFCFC;
}
.page-cart-footer .opt-btn {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  margin: 0 16px 0 24px;
  background: transparent;
  border: 0 none;
  cursor: pointer;
}
.page-cart-footer .opt-btn:hover {
  color: #000;
  color: var(--font-title);
}
.page-cart-footer .btn {
  width: 114px;
  height: 38px;
  font-size: 16px;
  color: #fff;
  margin-left: 24px;
  border: 0 none;
  border-radius: 8px;
  background: #4754ff;
  background: var(--color);
  margin-top: -2px;
}
.page-cart-footer .btn:hover, .page-cart-footer .btn:focus {
  cursor: pointer;
  background: rgba(71, 84, 255, 0.8);
  background: var(--color-active);
}
.page-cart-footer .btn:disabled {
  background: #b3b3b3;
  background: var(--disabled);
  cursor: not-allowed;
}
.page-cart-footer .text {
  margin-right: 24px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
}
.page-cart-footer .text .hint {
  font-size: 18px;
  font-weight: 700;
  color: #F43005;
  color: var(--orangey-red);
  margin: 0 6px;
}

.page-crumb {
  line-height: 58px;
  padding: 0 24px;
  font-size: 14px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  color: #000;
  color: var(--font-title);
}
.page-crumb .iconfont {
  font-size: 12px;
}

.order-create-wrap {
  padding: 0 14px;
}
.order-create-wrap .cart-table .cart-table-head .item {
  text-align: center;
}
.order-create-wrap .cart-table .cart-table-head .item:nth-child(2), .order-create-wrap .cart-table .cart-table-head .item:nth-child(3) {
  text-align: left;
}
.order-create-wrap .cart-table .cart-table-body-tr .tr-body .item {
  text-align: center;
}
.order-create-wrap .cart-table .cart-table-body-tr .tr-body .item:nth-child(2), .order-create-wrap .cart-table .cart-table-body-tr .tr-body .item:nth-child(3) {
  text-align: left;
}
.order-create-wrap .cart-table .cart-table-body-tr .tr-body .img-box {
  width: 64px;
  height: 64px;
  line-height: 58px;
  text-align: center;
}

.create-order-headline {
  height: 50px;
  padding-top: 18px;
  line-height: 32px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.create-order-headline:before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 14px;
  margin-right: 6px;
  background: #000;
  background: var(--font-title);
}
.create-order-headline .txt-btn {
  float: right;
  height: 32px;
  font-size: 14px;
  border: 0 none;
  background: transparent;
  color: #4754ff;
  color: var(--color);
}
.create-order-headline .btn {
  float: right;
  height: 32px;
  font-size: 14px;
  border: 0 none;
  background: transparent;
  margin-left: 10px;
}

.create-order-expand {
  display: flex;
  padding: 16px 0 0;
  flex-wrap: wrap;
}
.create-order-expand .expand-item-card {
  flex: 1 1;
  max-width: 33.3333%;
  padding-right: 40px;
  margin-bottom: 8px;
}
.create-order-expand .expand-item-card .title-box {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  margin-bottom: 8px;
}
.create-order-expand .expand-item-card .main-box .ant-radio-wrapper {
  display: block;
  margin-bottom: 8px;
}
.create-order-expand .expand-item-card .tax-row {
  font-size: 14px;
}
.create-order-expand .expand-item-card .tax-row .btn {
  margin-left: 1em;
  color: #1A81E8;
  cursor: pointer;
}
.create-order-expand .expand-item-card .hint-txt {
  color: #FF6350;
}
.create-order-expand .expand-item-card.all-width-card {
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  flex: auto;
}

.create-order-total {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.create-order-total td:first-child {
  text-align: right;
  padding-right: 8px;
}
.create-order-total .price {
  font-size: 18px;
  color: #4754ff;
  color: var(--font-high);
}
.create-order-total .icon-shuoming {
  color: #F5A623;
  margin-right: 2px;
  font-size: 16px;
  cursor: pointer;
}
.create-order-total .select-tag {
  cursor: pointer;
}
.create-order-total .select-tag .tag {
  font-size: 12px;
  line-height: 16px;
  padding: 0 6px;
  border-radius: 2px;
  color: #4754ff;
  color: var(--font-high);
  border: 1px solid #4754ff;
  border: 1px solid var(--color-hint);
}
.create-order-total .select-tag .icon-jump {
  font-size: 12px;
  margin-left: 4px;
  color: #4754ff;
  color: var(--font-high);
}

.create-order-address-title {
  width: 100%;
  height: 48px;
}
.create-order-address-title .txt-btn {
  float: right;
  height: 32px;
  font-size: 14px;
  border: 0 none;
  background: transparent;
  color: #4754ff;
  color: var(--color);
}
.create-order-address-title .btn {
  float: right;
  height: 32px;
  font-size: 14px;
  border: 0 none;
  background: transparent;
  margin-left: 10px;
}

.create-order-address {
  display: flex;
  flex: 0 1;
  flex-wrap: wrap;
}
.create-order-address .ant-empty {
  width: 100%;
}
.create-order-address .address-card {
  position: relative;
  width: 350px;
  padding: 16px;
  margin: 0 24px 16px 0;
  font-size: 14px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  border-radius: 4px;
  cursor: pointer;
}
.create-order-address .address-card .icon-selected {
  position: absolute;
  right: -1px;
  bottom: -1px;
  line-height: 1;
  font-size: 18px;
  text-align: right;
  visibility: hidden;
  color: #4754ff;
  color: var(--color);
}
.create-order-address .address-card.address-card-selected {
  border: 2px solid #4754ff;
  border: 2px solid var(--color);
}
.create-order-address .address-card.address-card-selected .icon-selected {
  visibility: visible;
}
.create-order-address .address-card .head {
  line-height: 38px;
  margin-bottom: 12px;
  color: #000;
  color: var(--font-title);
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.create-order-address .address-card .head .tips {
  float: right;
}
.create-order-address .address-card .body {
  color: #666;
  color: var(--font-normal);
  line-height: 22px;
}
.create-order-address .address-card .operate {
  position: absolute;
  right: 16px;
  bottom: 16px;
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}
.create-order-address .address-card .operate .btn:hover {
  color: #4754ff;
  color: var(--color);
}

.create-order-upload {
  margin-top: 10px;
}
.create-order-upload .headline {
  font-size: 14px;
  margin-bottom: 8px;
  color: #999;
  color: var(--font-tips);
}
.create-order-upload .image-upload-btn {
  width: 80px;
  height: 80px;
  padding-bottom: 0;
}

.order-promotion-box {
  padding: 15px 16px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin: 12px 0;
  font-size: 14px;
}
.order-promotion-box .tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  padding: 0 8px;
  height: 18px;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #FF6350;
  color: #FF6350;
  border-radius: 4px;
}
.order-promotion-box .text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
}
.order-promotion-box .tips {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  color: #FF6350;
}
.order-promotion-box .btn {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  padding: 0 8px;
  height: 20px;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  color: #666;
  color: var(--font-normal);
  border-radius: 4px;
  cursor: pointer;
}
.order-promotion-box .btn .iconfont {
  font-size: 12px;
  margin-left: 4px;
}

.present-popover-box .ant-popover-inner-content {
  padding: 0;
}

.present-info-wrap {
  width: 240px;
  height: 300px;
  overflow: auto;
  padding: 12px;
}
.present-info-wrap .present-card {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 12px;
  margin: 12px 0;
  cursor: pointer;
}
.present-info-wrap .present-card:hover {
  color: #4754ff;
  color: var(--color);
}
.present-info-wrap .present-card .img-box {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-right: 12px;
}
.present-info-wrap .present-card .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.present-info-wrap .present-card .info-box {
  flex: 1 1;
  width: 0;
}
.present-info-wrap .present-card .info-box .hint {
  color: #4754ff;
  color: var(--font-high);
}

.goods-promotion-box {
  font-size: 14px;
}
.goods-promotion-box .tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  padding: 0 8px;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  background: #FF6350;
  color: #fff;
  border-radius: 3px;
}
.goods-promotion-box .text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  color: #000;
  color: var(--font-title);
}
.goods-promotion-box .tips {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  color: #FF6350;
}
.goods-promotion-box .btn {
  display: inline-block;
  vertical-align: middle;
  color: #3399FF;
  cursor: pointer;
}

.order-promotion-present-box .cart-table-body-tr {
  border-bottom: 0 none;
}
.order-promotion-present-box .cart-table-body-tr .tr-body .item {
  padding: 0 10px;
}
.order-promotion-present-box .cart-table-body-tr .tr-body .item .img-box {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 32px;
}

.cart-free-goods-box {
  margin-top: 12px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.cart-free-goods-box .headline {
  padding: 0 16px;
  line-height: 46px;
  color: #000;
  color: var(--font-title);
}
.cart-free-goods-box .headline .tag {
  display: inline-block;
  line-height: 18px;
  padding: 0 12px;
  margin-right: 8px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color-hint);
  color: #4754ff;
  color: var(--color-hint);
  border-radius: 4px;
}
.cart-free-goods-box .headline .btn {
  border: 0 none;
  margin-left: 16px;
  background-color: transparent;
  color: #3399FF;
}
.cart-free-goods-box .headline .btn .iconfont {
  font-size: 12px;
}
.cart-free-goods-box .cart-table-body {
  margin: 0 16px;
  border-top: 1px solid #DDDDDD;
  border-top: 1px solid var(--border-deep);
}
.cart-free-goods-box .cart-table-body .cart-table-body-tr {
  border: 0 none;
}
.cart-free-goods-box .cart-table-body .cart-table-body-tr .img-box {
  width: 40px;
  height: 40px;
  line-height: 38px;
}
.cart-free-goods-box .cart-table-body .cart-table-body-tr .img-box img {
  vert-align: top;
}

.cart-free-goods-list {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: -24px;
  padding: 24px;
  max-height: 440px;
  overflow: auto;
}
.cart-free-goods-list .free-goods-item {
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.cart-free-goods-list .free-goods-item:nth-child(2n+1) {
  padding-right: 8px;
}
.cart-free-goods-list .free-goods-item:nth-child(2n) {
  padding-left: 8px;
}
.cart-free-goods-list .free-goods-item .img-box {
  width: 80px;
  height: 80px;
  flex: 0 0 auto;
  margin: 0 16px 0 8px;
}
.cart-free-goods-list .free-goods-item .cont-box .name {
  color: #000;
  color: var(--font-title);
}
.cart-free-goods-list .free-goods-item .cont-box .format {
  color: #999;
  color: var(--font-tips);
}
.cart-free-goods-list .free-goods-item .cont-box del {
  padding-left: 4px;
  color: #999;
  color: var(--font-tips);
}

.shop-cart-goods-promotion {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;
}
.shop-cart-goods-promotion .tag {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  font-size: 10px;
  color: #fff;
  background: #ff3838;
  border-radius: 3px;
  padding: 0 10px;
  width: 50px;
  height: 16px;
  margin-right: 8px;
}
.shop-cart-goods-promotion .tag i {
  flex: 1 1;
  text-align: center;
}
.shop-cart-goods-promotion .txt {
  font-size: 12px;
  color: #666;
  color: var(--font-normal);
  vertical-align: middle;
}
.shop-cart-goods-promotion .txt .text {
  margin-right: 10px;
}
.shop-cart-goods-promotion .txt .tips {
  color: #3399FF;
}
.shop-cart-goods-promotion .txt-meet {
  color: #000 !important;
  color: var(--font-title) !important;
}

.goods-package-tag {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  font-size: 10px;
  color: #fff;
  background: #ff3838;
  border-radius: 3px;
  padding: 0 10px;
  width: 50px;
  height: 16px;
  margin-right: 8px;
}
.goods-package-tag i {
  flex: 1 1;
  text-align: center;
}

.order-pay-page-wrap .breadcrumb-block-box {
  margin: 12px 0;
}

.order-pay-head-wrap {
  background: #F7F8FA;
  margin-bottom: 16px;
  padding: 0 24px 16px;
}
.order-pay-head-wrap .pay-info-wrap {
  padding-top: 16px;
}
.order-pay-head-wrap .pay-info-wrap .left-wrap .svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  margin-top: -4px;
}
.order-pay-head-wrap .pay-info-wrap .left-wrap .title {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  color: #000;
  color: var(--font-title);
  margin-bottom: 8px;
}
.order-pay-head-wrap .pay-info-wrap .left-wrap .tips {
  font-size: 16px;
  color: #666;
  color: var(--font-normal);
  padding-left: 38px;
}
.order-pay-head-wrap .pay-info-wrap .left-wrap .time {
  font-size: 16px;
  font-weight: bold;
}
.order-pay-head-wrap .pay-info-wrap .left-wrap .time i {
  color: #4754ff;
  color: var(--font-high);
}
.order-pay-head-wrap .pay-info-wrap .money-info-wrap {
  margin-bottom: 17px;
  color: #666;
  color: var(--font-normal);
}
.order-pay-head-wrap .pay-info-wrap .money-info-wrap span {
  color: #F43005;
  color: var(--orangey-red);
}
.order-pay-head-wrap .pay-info-wrap .money-info-wrap span.wait-pay {
  font-weight: bold;
  font-size: 24px;
}
.order-pay-head-wrap .order-info-wrap {
  line-height: 1;
}
.order-pay-head-wrap .order-info-wrap dt {
  color: #000;
  color: var(--font-title);
  margin-bottom: 12px;
}
.order-pay-head-wrap .order-info-wrap dt .to-detail-btn {
  float: right;
  color: #666;
  color: var(--font-normal);
  font-weight: normal;
  cursor: pointer;
}
.order-pay-head-wrap .order-info-wrap dt .to-detail-btn:hover {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
}
.order-pay-head-wrap .order-info-wrap dd {
  color: #666;
  color: var(--font-normal);
}
.order-pay-head-wrap .order-info-wrap dd:not(:last-child) {
  margin-bottom: 12px;
}
.order-pay-head-wrap .order-info-wrap .item-padding {
  margin-right: 50px;
}
.order-pay-head-wrap .line-padding {
  padding: 6px 0;
}
.order-pay-head-wrap .order-btn {
  font-size: 10px;
  color: #666;
  color: var(--font-normal);
  cursor: pointer;
  text-align: center;
  padding-bottom: 16px;
}
.order-pay-head-wrap .order-btn .iconfont {
  font-size: 10px;
  margin-left: 4px;
}

.order-pay-body-wrap {
  padding-bottom: 30px;
}
.order-pay-body-wrap .pay-money-wrap {
  padding: 24px;
  font-size: 18px;
  color: #000;
  color: var(--font-title);
  margin-bottom: 8px;
  background: #fff;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box {
  background: #fff;
  margin-bottom: 10px;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  padding: 0 24px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin: 0px -1px -1px 0px;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .txt {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #222;
  font-weight: bold;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .txt span {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  font-weight: normal;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .bank-txt {
  display: inline-block;
  padding-left: 4px;
  vertical-align: middle;
  font-size: 12px;
  color: #4754ff;
  color: var(--color);
  cursor: pointer;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .bank-txt i {
  display: inline-block;
  margin-right: 4px;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .item-money {
  color: #666;
  color: var(--font-normal);
  margin-left: 10px;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .item-money-remain {
  margin-left: 10px;
  color: #F43005;
  color: var(--orangey-red);
  font-weight: bold;
  font-size: 24px;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .balance-money {
  padding-right: 4px;
}
.order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-total-box {
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  text-align: right;
}
.order-pay-body-wrap .pay-main-wrap dt {
  padding: 16px 0;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  margin-bottom: 18px;
  line-height: 1;
}
.order-pay-body-wrap .pay-main-wrap dt .iconfont {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 8px;
  cursor: pointer;
  color: #999;
  color: var(--font-tips);
}
.order-pay-body-wrap .pay-main-wrap dt .icon-check-s {
  color: #4754ff;
  color: var(--color);
}
.order-pay-body-wrap .pay-main-wrap dt .svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.order-pay-body-wrap .pay-main-wrap dt .txt {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #222;
  font-weight: bold;
}
.order-pay-body-wrap .pay-main-wrap dt .txt span {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  font-weight: normal;
}
.order-pay-body-wrap .pay-main-wrap dt .tips {
  padding-left: 28px;
  font-size: 12px;
  color: #666;
  color: var(--font-normal);
}
.order-pay-body-wrap .pay-main-wrap dt .right-box {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  line-height: 32px;
}
.order-pay-body-wrap .pay-main-wrap dd {
  margin-bottom: 18px;
}
.order-pay-body-wrap .pay-main-wrap dd .svg-icon {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.order-pay-body-wrap .pay-main-wrap dd .txt {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #222;
  font-weight: bold;
}
.order-pay-body-wrap .pay-main-wrap dd .txt span {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  font-weight: normal;
}
.order-pay-body-wrap .pay-main-wrap dd .bank-txt {
  display: inline-block;
  padding-left: 4px;
  vertical-align: middle;
  font-size: 12px;
  color: #4754ff;
  color: var(--color);
  cursor: pointer;
}
.order-pay-body-wrap .pay-main-wrap dd .bank-txt i {
  display: inline-block;
  margin-right: 4px;
}
.order-pay-body-wrap .order-pay-remark {
  border: 0 none;
  background: #fff;
  padding: 16px 0;
  margin-bottom: 10px;
}
.order-pay-body-wrap .order-pay-remark .txt-red {
  margin-bottom: 4px;
}
.order-pay-body-wrap .order-pay-remark .image-upload {
  margin-top: 12px;
}
.order-pay-body-wrap .btn-box {
  text-align: right;
}
.order-pay-body-wrap .btn-box .btn {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 48px;
  background: #4754ff;
  background: var(--color);
  border-radius: 4px;
  border: 0 none;
  margin-left: 20px;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.order-pay-body-wrap .btn-box .btn:nth-child(1) {
  background: #fff;
  color: #4754ff;
  color: var(--color);
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
}

.page-order-pay-result {
  margin: 10px auto;
  padding: 22px 16px;
}

.order-pay-result-head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 32px 0;
  font-weight: 700;
  background: #EFFAEC;
}
.order-pay-result-head .svg-icon {
  width: 56px;
  height: 56px;
  margin-right: 16px;
}

.order-pay-result-detail table {
  margin: 16px auto;
}
.order-pay-result-detail table td {
  padding: 2px;
}

.order-pay-result-operate {
  text-align: center;
}
.order-pay-result-operate .btn {
  width: 116px;
  height: 32px;
  margin: 4px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  background: transparent;
}

.order-list-page {
  padding: 16px;
}
.order-list-page .breadcrumb-block-box {
  margin-left: -220px;
  margin-bottom: 12px;
}
.order-list-page .ant-pagination {
  padding: 32px 0 8px;
  text-align: center;
}
.order-list-page .ant-spin {
  display: block;
  padding: 48px 0 36px;
}
.order-list-page .ant-empty {
  padding: 48px 0 36px;
}
.order-list-page .order-list-content {
  min-height: 400px;
}
.order-list-page .order-list-head {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.order-list-page .order-list-head .tabs-box .tab {
  display: inline-block;
  vertical-align: middle;
  margin: 0 14px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.order-list-page .order-list-head .tabs-box .tab:hover {
  color: #4754ff;
  color: var(--color);
}
.order-list-page .order-list-head .tabs-box .tab.active {
  color: #4754ff;
  color: var(--color);
}
.order-list-page .order-list-head .search-box .input-wrap, .order-list-page .order-list-head .search-box .select-box {
  display: inline-block;
  vertical-align: middle;
}
.order-list-page .order-list-head .search-box .input-wrap {
  margin-right: 8px;
}
.order-list-page .order-list-body table {
  width: 100%;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  table-layout: fixed;
}
.order-list-page .order-list-body table thead tr {
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.order-list-page .order-list-body table thead th {
  background: #f5f5f5;
  line-height: 24px;
  padding: 8px 16px;
  text-align: center;
  vertical-align: middle;
  width: 128px;
}
.order-list-page .order-list-body table thead th:nth-child(1) {
  text-align: left;
  width: 416px;
}
.order-list-page .order-list-body table thead th:nth-child {
  width: 162px;
}
.order-list-page .order-list-body table tbody:before {
  content: "";
  display: block;
  height: 16px;
}
.order-list-page .order-list-body table tbody tr:first-child {
  background-color: #f5f5f5;
  line-height: 40px;
  padding: 0 16px;
}
.order-list-page .order-list-body table tbody td {
  padding: 16px;
  text-align: center;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.order-list-page .order-list-body table tbody td:first-child {
  text-align: left;
  padding: 0;
}
.order-list-page .order-list-body table tbody td .ant-btn {
  margin-bottom: 8px;
}
.order-list-page .order-list-body table .col {
  display: inline-block;
  padding: 0 16px;
  margin-right: 32px;
  color: #999;
  color: var(--font-tips);
}
.order-list-page .order-list-body table .code {
  color: #666;
  color: var(--font-normal);
}
.order-list-page .order-list-body table .status {
  color: #999;
  color: var(--font-tips);
}
.order-list-page .order-list-body table .link {
  display: block;
  color: #4754ff;
  color: var(--color);
}
.order-list-page .order-list-body table .link:hover {
  color: #4754ff;
  color: var(--color);
}
.order-list-page .order-list-body table .link-cancel-order {
  color: red;
}
.order-list-page .order-list-body table .link-cancel-order:hover {
  color: red;
}
.order-list-page .order-list-body table .text {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
}
.order-list-page .order-list-body table .img-box {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  position: relative;
}
.order-list-page .order-list-body table .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.order-list-page .order-list-body table .img-box:after {
  content: attr(data-number);
  display: inline-block;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  bottom: 0;
}
.order-list-page .order-list-body table .item-list {
  vertical-align: top;
}
.order-list-page .order-list-body table .item-list .item {
  display: flex;
  justify-content: space-between;
  padding: 22px 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.order-list-page .order-list-body table .item-list .item:last-child {
  border-bottom: 0;
}
.order-list-page .order-list-body table .item-list .item .name {
  flex: 1 1;
  padding-right: 16px;
}

.order-list-page.normal-list .order-list-body table thead th {
  width: 138px;
}
.order-list-page.normal-list .order-list-body table thead th:nth-child(1) {
  text-align: center;
  width: 178px;
}
.order-list-page.normal-list .order-list-body table thead th:nth-child(2) {
  text-align: center;
  width: 220px;
}
.order-list-page.normal-list .order-list-body table thead th:last-child {
  width: 100px;
}
.order-list-page.normal-list .order-list-body table tbody td.normal-first-td {
  text-align: center;
}

.order-detail-page {
  padding: 16px;
  background: #fff;
}
.order-detail-page .ant-spin {
  width: 100%;
  text-align: center;
  margin: 48px 0;
}
.order-detail-page .more-refund-rec {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
}

.order-detail-chunk {
  display: flex;
  padding: 16px 0;
  margin-bottom: 16px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.order-detail-chunk .chunk-column {
  flex: 1 1;
  padding: 0 16px;
  border-left: 1px solid #DDDDDD;
  border-left: 1px solid var(--border-deep);
}
.order-detail-chunk .chunk-column:first-child {
  border: 0 none;
}
.order-detail-chunk .order-detail-code {
  flex: 0 0 260px;
}
.order-detail-chunk .order-detail-code .status {
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center;
  color: #000;
  color: var(--font-title);
}
.order-detail-chunk .order-detail-steps {
  display: flex;
  justify-content: center;
}
.order-detail-chunk .order-detail-steps .step {
  text-align: center;
  display: flex;
}
.order-detail-chunk .order-detail-steps .step:last-child .arrow {
  display: none;
}
.order-detail-chunk .order-detail-steps .step-active .iconfont {
  color: #4754ff;
  color: var(--color);
}
.order-detail-chunk .order-detail-steps .detail {
  width: 4em;
}
.order-detail-chunk .order-detail-steps .detail-active .iconfont {
  color: #4754ff;
  color: var(--color);
}
.order-detail-chunk .order-detail-steps .iconfont {
  font-size: 24px;
  color: #999;
  color: var(--font-tips);
}
.order-detail-chunk .order-detail-steps .icon-progress-bar {
  font-size: 17px;
}
.order-detail-chunk .order-detail-steps .label {
  display: block;
  color: #000;
  color: var(--font-title);
}
.order-detail-chunk .order-detail-steps .tips {
  display: inline-block;
  width: 90px;
  text-align: left;
  font-size: 12px;
  line-height: 1.3;
  color: #999;
  color: var(--font-tips);
}
.order-detail-chunk .order-detail-steps .arrow {
  padding-top: 4px;
}
.order-detail-chunk .order-detail-steps .arrow-active .iconfont {
  color: #4754ff;
  color: var(--color);
}
.order-detail-chunk .order-log-btn, .order-detail-chunk .pick-up-code {
  font-size: 14px;
  padding-top: 10px;
  padding-left: 42px;
  color: #666;
  color: var(--font-normal);
  cursor: pointer;
}
.order-detail-chunk .pick-up-code {
  font-size: 16px;
  color: #F43005;
  color: var(--orangey-red);
}
.order-detail-chunk dl dt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
}
.order-detail-chunk dl dt .btn-logistics {
  width: 80px;
  height: 24px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  color: #4754ff;
  color: var(--color);
  background: transparent;
  border-radius: 2px;
}
.order-detail-chunk dl dt .btn-logistics:hover {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}
.order-detail-chunk dl dt .btn-pay {
  width: 80px;
  height: 24px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  color: #4754ff;
  color: var(--color);
  background: transparent;
  border-radius: 2px;
}
.order-detail-chunk dl dt .btn-pay:hover {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}
.order-detail-chunk dl dd {
  display: flex;
  margin-bottom: 8px;
  font-size: 14px;
}
.order-detail-chunk dl dd .label {
  width: 120px;
  color: #666;
  color: var(--font-normal);
}
.order-detail-chunk dl dd .value {
  flex: 1 1;
  width: 0;
  padding-left: 16px;
  color: #000;
  color: var(--font-title);
  word-break: break-all;
}
.order-detail-chunk dl dd .img-box {
  display: inline-block;
  vertical-align: top;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  border: 1px solid #f5f5f5;
  margin-right: 4px;
  margin-bottom: 4px;
}
.order-detail-chunk dl dd .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.order-detail-chunk dl dd .tips {
  cursor: pointer;
}

.workorder-detail-chunk .order-detail-steps .icon-progress-bar {
  font-size: 14px;
}

.order-detail-table .order-detail-table-title,
.order-refund-table .order-detail-table-title,
.refund-detail-table .order-detail-table-title {
  line-height: 24px;
  margin: 6px 0;
}
.order-detail-table table,
.order-refund-table table,
.refund-detail-table table {
  width: 100%;
  font-size: 13px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  color: #666;
  color: var(--font-normal);
}
.order-detail-table table th,
.order-refund-table table th,
.refund-detail-table table th {
  padding: 0 16px;
  line-height: 40px;
  background: #f5f5f5;
  text-align: left;
  font-weight: normal;
}
.order-detail-table table th:nth-child(1),
.order-refund-table table th:nth-child(1),
.refund-detail-table table th:nth-child(1) {
  width: 30%;
  font-weight: 600;
}
.order-detail-table table th:nth-child(2),
.order-refund-table table th:nth-child(2),
.refund-detail-table table th:nth-child(2) {
  width: 10%;
}
.order-detail-table table th:nth-child(3),
.order-refund-table table th:nth-child(3),
.refund-detail-table table th:nth-child(3) {
  width: 10%;
}
.order-detail-table table th:nth-child(4),
.order-refund-table table th:nth-child(4),
.refund-detail-table table th:nth-child(4) {
  width: 10%;
}
.order-detail-table table th:nth-child(5),
.order-refund-table table th:nth-child(5),
.refund-detail-table table th:nth-child(5) {
  width: 10%;
}
.order-detail-table table th:nth-child(6),
.order-refund-table table th:nth-child(6),
.refund-detail-table table th:nth-child(6) {
  width: 10%;
}
.order-detail-table table th:nth-child(7),
.order-refund-table table th:nth-child(7),
.refund-detail-table table th:nth-child(7) {
  width: 10%;
}
.order-detail-table table th:nth-child(8),
.order-refund-table table th:nth-child(8),
.refund-detail-table table th:nth-child(8) {
  width: 20%;
  text-align: center;
}
.order-detail-table table td,
.order-refund-table table td,
.refund-detail-table table td {
  padding: 8px;
  line-height: 22px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.order-detail-table table td:nth-child(1),
.order-refund-table table td:nth-child(1),
.refund-detail-table table td:nth-child(1) {
  color: #000;
  color: var(--font-title);
}
.order-detail-table table td:nth-child(8),
.order-refund-table table td:nth-child(8),
.refund-detail-table table td:nth-child(8) {
  border-left: 1px solid #DDDDDD;
  border-left: 1px solid var(--border-deep);
  text-align: center;
  vertical-align: middle;
}
.order-detail-table table td[rowSpan],
.order-refund-table table td[rowSpan],
.refund-detail-table table td[rowSpan] {
  border-left: 1px solid #DDDDDD;
  border-left: 1px solid var(--border-deep);
  border-right: 1px solid #DDDDDD;
  border-right: 1px solid var(--border-deep);
  text-align: center;
}
.order-detail-table table td .promotion-tips-wrap,
.order-refund-table table td .promotion-tips-wrap,
.refund-detail-table table td .promotion-tips-wrap {
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}
.order-detail-table table td .promotion-tips-wrap p,
.order-refund-table table td .promotion-tips-wrap p,
.refund-detail-table table td .promotion-tips-wrap p {
  color: #000;
  color: var(--font-title);
}
.order-detail-table table .goods-card-item,
.order-refund-table table .goods-card-item,
.refund-detail-table table .goods-card-item {
  display: flex;
}
.order-detail-table table .goods-card-item .img-box,
.order-refund-table table .goods-card-item .img-box,
.refund-detail-table table .goods-card-item .img-box {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 70px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin-right: 10px;
}
.order-detail-table table .goods-card-item .img-box img,
.order-refund-table table .goods-card-item .img-box img,
.refund-detail-table table .goods-card-item .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.order-detail-table table .goods-card-item .info-box,
.order-refund-table table .goods-card-item .info-box,
.refund-detail-table table .goods-card-item .info-box {
  flex: 1 1;
}
.order-detail-table table .goods-card-item .info-box .title,
.order-refund-table table .goods-card-item .info-box .title,
.refund-detail-table table .goods-card-item .info-box .title {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  line-height: 1.5;
}
.order-detail-table table .goods-card-item .info-box .tips,
.order-refund-table table .goods-card-item .info-box .tips,
.refund-detail-table table .goods-card-item .info-box .tips {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}
.order-detail-table table .opt-btn,
.order-refund-table table .opt-btn,
.refund-detail-table table .opt-btn {
  background: transparent;
  border: 0 none;
}
.order-detail-table table .opt-btn.btn-disabled,
.order-refund-table table .opt-btn.btn-disabled,
.refund-detail-table table .opt-btn.btn-disabled {
  color: #b3b3b3;
  color: var(--disabled);
}
.order-detail-table .tips,
.order-refund-table .tips,
.refund-detail-table .tips {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}

.order-refund-table .img-box {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 70px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin-right: 10px;
}
.order-refund-table .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.order-refund-table table th {
  padding: 0 16px;
  line-height: 40px;
  background: #f5f5f5;
  text-align: left;
}
.order-refund-table table th:nth-child(1) {
  width: 5%;
}
.order-refund-table table th:nth-child(2) {
  width: 8%;
}
.order-refund-table table th:nth-child(3) {
  width: 15%;
  font-weight: 600;
}
.order-refund-table table th:nth-child(4) {
  width: 15%;
}
.order-refund-table table th:nth-child(5) {
  width: 10%;
}
.order-refund-table table th:nth-child(6) {
  width: 10%;
}
.order-refund-table table th:nth-child(7) {
  width: 10%;
}
.order-refund-table table th:nth-child(8) {
  width: 10%;
}
.order-refund-table table th:nth-child(9) {
  width: 17%;
  text-align: center;
}
.order-refund-table table td:nth-child(8) {
  border-left: none;
  text-align: left;
  vertical-align: baseline;
}
.order-refund-table table td:nth-child(9) {
  border-left: 1px solid #DDDDDD;
  border-left: 1px solid var(--border-deep);
  text-align: center;
  vertical-align: middle;
}

.refund-detail-table table th:nth-child(7) {
  text-align: center;
  vertical-align: middle;
}
.refund-detail-table table td:nth-child(7) {
  border-left: 1px solid #DDDDDD;
  border-left: 1px solid var(--border-deep);
  text-align: center;
}

.order-total-table {
  border: 1px soild #DDDDDD;
  border: 1px soild var(--border-deep);
  position: relative;
  display: flex;
  justify-content: space-between;
}
.order-total-table .type-hint {
  position: absolute;
  top: 12px;
  left: 0;
  font-size: 14px;
  color: #4754ff;
  color: var(--font-high);
}
.order-total-table .order-total-discount {
  width: 800px;
}
.order-total-table .order-total-discount .discount-item {
  margin: 12px 0;
}
.order-total-table .order-total-discount .discount-item .discount-name {
  display: inline-block;
  padding: 0 6px;
  color: #ef0505;
  border: 1px solid;
  border-radius: 2px;
  margin-right: 12px;
  background: #ffe1e1;
}
.order-total-table table {
  margin-left: auto;
}
.order-total-table table td {
  padding: 10px 12px 0;
}
.order-total-table table td:nth-child(1) {
  text-align: right;
}
.order-total-table table .total {
  font-size: 16px;
  font-weight: 600;
  color: #4754ff;
  color: var(--font-high);
}

.back-edit-box {
  min-height: 400px;
}
.back-edit-box .goods-info-box {
  margin-bottom: 16px;
}
.back-edit-box .goods-info-box table {
  width: 100%;
  table-layout: fixed;
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  font-weight: normal;
  text-align: left;
}
.back-edit-box .goods-info-box th {
  height: 32px;
  vertical-align: 32px;
  padding-right: 16px;
}
.back-edit-box .goods-info-box td {
  padding-right: 16px;
}
.back-edit-box .goods-info-box td .txt {
  display: inline-block;
  width: 100%;
}
.back-edit-box .remark-box .text-area {
  position: relative;
}
.back-edit-box .remark-box .text-area .ant-input-textarea-clear-icon {
  margin-right: 28px;
}
.back-edit-box .remark-box .text-area textarea {
  padding-bottom: 32px;
}
.back-edit-box .remark-box .text-area:after {
  content: attr(data-number);
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
  position: absolute;
  right: 28px;
  bottom: 6px;
}
.back-edit-box .remark-box .img-upload-box {
  margin-top: 16px;
}
.back-edit-box .remark-box .img-upload-box .title {
  margin-bottom: 8px;
  font-size: 14px;
  color: #999;
  color: var(--font-tips);
}

.back-result-box {
  margin-top: 40px;
  padding-top: 16px;
  border-top: 1px solid #DDDDDD;
  border-top: 1px solid var(--border-deep);
}
.back-result-box li {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  margin-bottom: 4px;
}
.back-result-box li span:first-child {
  color: #666;
  color: var(--font-normal);
}

.order-detail-out {
  margin-bottom: 16px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.order-detail-out .headline {
  padding: 0 16px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  background: #f5f5f5;
}
.order-detail-out .out-list-item {
  display: flex;
  padding: 8px 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.order-detail-out .out-list-item:last-child {
  border-bottom: 0 none;
}
.order-detail-out .out-list-item .img-box {
  position: relative;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 48px;
  overflow: hidden;
}
.order-detail-out .out-list-item .img-box .tips {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  line-height: 22px;
}
.order-detail-out .out-list-item .detail-box {
  padding: 0 16px;
  flex: 1 1;
  width: 0;
}
.order-detail-out .out-list-item .detail-box .name {
  color: #000;
  color: var(--font-title);
}
.order-detail-out .out-list-item .detail-box .tips {
  color: #999;
  color: var(--font-tips);
}
.order-detail-out .out-list-item .operate-box {
  align-self: center;
}
.order-detail-out .btn-logistics {
  width: 108px;
  height: 24px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin-right: 8px;
  color: #000;
  color: var(--font-title);
  background: transparent;
  border-radius: 2px;
}
.order-detail-out .btn-logistics:hover {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}
.order-detail-out .btn-receive {
  width: 108px;
  height: 24px;
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  color: #4754ff;
  color: var(--color);
  background: transparent;
  border-radius: 2px;
}
.order-detail-out .btn-receive:hover {
  color: rgba(71, 84, 255, 0.8);
  color: var(--color-active);
  border-color: rgba(71, 84, 255, 0.8);
  border-color: var(--color-active);
}

.order-log-page {
  padding: 16px;
  background: #fff;
}
.order-log-page table {
  width: 100%;
  font-size: 14px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  color: #666;
  color: var(--font-normal);
}
.order-log-page table th {
  padding: 0 16px;
  line-height: 40px;
  background: #f5f5f5;
  text-align: left;
}
.order-log-page table th:nth-child(1) {
  width: 250px;
  font-weight: 600;
}
.order-log-page table th:nth-child(2) {
  width: 680px;
}
.order-log-page table td {
  padding: 0 16px;
  line-height: 40px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}

.pay-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 600px;
  transform: translate(-50%, -50%);
}
.pay-modal .pay-modal-title {
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
  color: #000;
  color: var(--font-title);
  background: #fcfcfc;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.pay-modal .pay-modal-title .close {
  float: right;
  cursor: pointer;
  margin-right: 24px;
}
.pay-modal .pay-modal-title .close .iconfont {
  font-size: 15px;
}
.pay-modal .pay-modal-body {
  background: #fff;
  border-radius: 0 0 4px 4px;
}
.pay-modal .pay-modal-body .pay-modal-body-head {
  padding: 12px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
}
.pay-modal .pay-modal-body .pau-modal-body-main {
  padding: 48px 0;
  text-align: center;
}
.pay-modal .pay-modal-body .pau-modal-body-main .main-box {
  display: inline-block;
}
.pay-modal .pay-modal-body .pau-modal-body-main .main-box .price-box {
  font-size: 18px;
  font-weight: bold;
  color: #F43005;
  color: var(--orangey-red);
}
.pay-modal .pay-modal-body .pau-modal-body-main .main-box .qrcode-box {
  padding: 2px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  line-height: 1;
}
.pay-modal .pay-modal-body .pau-modal-body-main .main-box .tips-box {
  margin-top: 12px;
  line-height: 1.3;
  font-size: 12px;
  color: #666;
  color: var(--font-normal);
}

.wechat-pay-page-box .wrap {
  width: 1200px;
  margin: 0 auto;
}
.wechat-pay-page-box .wechat-pay-page-head {
  background: #fff;
  border-bottom: #DDDDDD;
  border-bottom: var(--border-deep);
  padding: 16px 0;
}
.wechat-pay-page-box .wechat-pay-page-head img, .wechat-pay-page-box .wechat-pay-page-head span {
  display: inline-block;
  vertical-align: middle;
}
.wechat-pay-page-box .wechat-pay-page-head span {
  padding-left: 6px;
  margin-left: 6px;
  position: relative;
}
.wechat-pay-page-box .wechat-pay-page-head span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 32px;
  margin-top: -6px;
  background: #DDDDDD;
  background: var(--border-deep);
}
.wechat-pay-page-box .wechat-pay-page-info-box {
  position: relative;
  padding: 32px 24px;
  border-bottom: 3px solid #b3b3b3;
  color: #000;
  color: var(--font-title);
}
.wechat-pay-page-box .wechat-pay-page-info-box .title {
  font-size: 12px;
  margin-bottom: 18px;
}
.wechat-pay-page-box .wechat-pay-page-info-box .info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.wechat-pay-page-box .wechat-pay-page-info-box .info i {
  font-weight: 600;
  display: inline-block;
  margin-right: 32px;
}
.wechat-pay-page-box .wechat-pay-page-info-box .info .price i {
  margin-right: 0;
  font-size: 18px;
  color: #4754ff;
  color: var(--font-high);
}
.wechat-pay-page-box .wechat-pay-page-info-box .btn {
  position: absolute;
  right: 20px;
  bottom: 0;
  width: 70px;
  height: 24px;
  border-radius: 2px 2px 0 0;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  background: #b3b3b3;
  cursor: pointer;
  color: #ffffff;
}
.wechat-pay-page-box .wechat-pat-page-content {
  min-height: 526px;
  border-bottom: 3px solid #b3b3b3;
  background: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.wechat-pay-page-box .wechat-pat-page-content .main-box {
  text-align: center;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  line-height: 1;
}
.wechat-pay-page-box .wechat-pat-page-content .main-box .price {
  font-size: 18px;
  color: #4754ff;
  color: var(--font-high);
  margin: 10px 0;
}
.wechat-pay-page-box .wechat-pat-page-content .main-box .qrcode-box {
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  padding: 6px;
  margin: 10px 0;
}

.user-page-box {
  padding: 16px;
  background: #fff;
  height: 100%;
}
.user-page-box .breadcrumb-block-box {
  margin-left: -220px;
  margin-bottom: 12px;
}
.user-page-box .left-box {
  flex: 1 1;
}
.user-page-box .user-info-card {
  border: 1px solid #f5f5f5;
  margin-bottom: 16px;
}
.user-page-box .user-info-card .info-box {
  padding: 10px;
  background: #F8F8F8;
  display: flex;
}
.user-page-box .user-info-card .info-box .user-card {
  flex: 1 1;
}
.user-page-box .user-info-card .info-box .account-box {
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.user-page-box .user-info-card .info-box .account-box .account-blance {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 12px;
}
.user-page-box .user-info-card .info-box .account-box .blance-title {
  color: rgba(0, 0, 0, 0.6);
  font-family: PingFang SC;
  font-size: 14px;
}
.user-page-box .user-info-card .info-box .account-box .blance-value {
  margin-left: 4px;
  color: #000;
  font-family: PingFang SC;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.user-page-box .user-info-card .order-statistic-box {
  height: 84px;
}
.user-page-box .user-info-card .order-statistic-box .statistic-box-card {
  height: 100%;
}
.user-page-box .info-list-card {
  border: 1px solid #f5f5f5;
  margin-bottom: 16px;
}
.user-page-box .info-list-card .info-list-card-head {
  height: 40px;
  line-height: 40px;
  background: #f8f8f8;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding: 0 16px;
}
.user-page-box .info-list-card .info-list-card-main .null-tips-box {
  padding: 40px 0;
}
.user-page-box .info-list-card .info-list-card-main .list-card-box {
  padding: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}
.user-page-box .info-list-card .info-list-card-main .list-card-box:last-child {
  border-bottom: 0 none;
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .img-box {
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  margin-right: 8px;
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .img-box img {
  max-height: 100%;
  max-width: 100%;
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .main-box {
  flex: 1 1;
  width: 0;
  line-height: 1;
  font-size: 12px;
  margin-right: 24px;
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .main-box .text-title {
  color: #000;
  color: var(--font-title);
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .main-box .text-tips {
  margin-top: 8px;
  color: #999;
  color: var(--font-tips);
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .btn-box .btn {
  width: 64px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid #dcdcdc;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  background: #fff;
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .btn-box .btn:hover {
  color: #fff;
  background: #4754ff;
  background: var(--color);
}
.user-page-box .info-list-card .info-list-card-main .list-card-box .tips-box {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}
.user-page-box .info-data-card {
  border: 1px solid #f5f5f5;
  margin-bottom: 16px;
}
.user-page-box .info-data-card .info-data-card-head {
  height: 40px;
  line-height: 40px;
  background: #f8f8f8;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding: 0 16px;
}
.user-page-box .info-data-card .info-data-card-body {
  padding: 16px;
  font-size: 12px;
  color: #666;
  color: var(--font-normal);
}
.user-page-box .info-data-card .info-data-card-body .row-card {
  margin-bottom: 10px;
}
.user-page-box .info-data-card .info-data-card-body .iconfont {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  font-size: 12px;
}
.user-page-box .right-box {
  width: 290px;
  margin-left: 16px;
}

.account-details-page-box {
  padding: 16px;
  background: #fff;
  height: 100%;
}
.account-details-page-box .breadcrumb-block-box {
  margin-left: -220px;
  margin-bottom: 12px;
}
.account-details-page-box .account-details-page-main-head {
  margin-bottom: 8px;
}
.account-details-page-box .account-details-page-main-head .input-wrap {
  display: inline-block;
  vertical-align: middle;
  width: 260px;
  margin-right: 14px;
}
.account-details-page-box .account-details-page-main-head .select-box {
  display: inline-block;
  vertical-align: middle;
  width: 260px;
  margin-right: 14px;
}
.account-details-page-box .account-details-page-main-body .statistics-box {
  height: 96px;
  background: #f5f5f5;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 16px;
}
.account-details-page-box .account-details-page-main-body .statistics-box .statistics-item {
  flex: 1 1;
  text-align: center;
}
.account-details-page-box .account-details-page-main-body .statistics-box .statistics-item .number {
  font-size: 20px;
  font-weight: bold;
}
.account-details-page-box .account-details-page-main-body .statistics-box .statistics-item .title {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}
.account-details-page-box .account-details-page-main-foot {
  text-align: center;
  margin-top: 16px;
}

.pick-up-list-page .account-details-page-main .account-details-page-main-head .input-wrap .ant-input-affix-wrapper {
  margin-right: 30px;
}
.pick-up-list-page .account-details-page-main-body .cont-table .tr-txt {
  cursor: pointer;
}
.pick-up-list-page .account-details-page-main-body .cont-table .tr-txt.active {
  background-color: pink;
}

.title-line-box {
  padding-left: 12px;
  position: relative;
  font-size: 14px;
  line-height: 28px;
  color: #000;
  color: var(--font-title);
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.title-line-box:before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 12px;
  background: #000;
  background: var(--font-title);
  position: absolute;
  left: 0;
  top: 8px;
}

.account-page-box {
  padding: 16px;
  background: #fff;
}
.account-page-box .breadcrumb-block-box {
  margin-left: -220px;
  margin-bottom: 12px;
}
.account-page-box .account-page-body .title-line-box {
  margin-bottom: 16px;
}
.account-page-box .account-page-body .form-item-box {
  width: 400px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.notices-details-page-box {
  margin-top: 14px;
  background: #fff;
}
.notices-details-page-box .notices-details-page-body {
  padding: 16px;
}
.notices-details-page-box .attachment-list a {
  display: flex;
}
.notices-details-page-box .attachment-list .img-box {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.notices-details-page-box .attachment-list .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.notices-details-page-box .attachment-list .img-box .svg-icon {
  width: 100%;
  height: 100%;
}
.notices-details-page-box .attachment-list .name {
  flex: 1 1;
}

.staff-page-box {
  margin-top: 14px;
  background: #fff;
}
.staff-page-box .staff-page-body {
  padding: 16px;
}
.staff-page-box .staff-page-body .add-btn-box {
  padding-bottom: 16px;
}
.staff-page-box .staff-page-body .add-btn-box .link-btn {
  border: 0 none;
  background: transparent;
  color: #4754ff;
  color: var(--color);
  font-size: 14px;
  margin-right: 16px;
  display: inline-block;
  vertical-align: middle;
}
.staff-page-box .staff-page-body .add-btn-box .link-btn[disabled] {
  color: #b3b3b3;
  color: var(--disabled);
}
.staff-page-box .staff-page-body .add-btn-box .link-btn .iconfont {
  margin-right: 4px;
  font-size: 14px;
}
.staff-page-box .staff-page-body .add-btn-box .tips {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}

.collect-list-box {
  margin-top: 14px;
  background: #fff;
  min-height: 100%;
}
.collect-list-box .collect-list-body {
  padding: 16px 0;
}
.collect-list-box .collect-list-body .filter-sheet-wrap {
  padding: 0;
}
.collect-list-box .collect-list-body .sort-sheet-box {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
}
.collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .btn {
  margin-left: 12px;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  padding: 0 8px;
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  background: #f5f5f5;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
}
.collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  margin-left: 12px;
  cursor: pointer;
}
.collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn:hover {
  color: #4754ff;
  color: var(--color);
}
.collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn .iconfont {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn .icon-check-s {
  color: #4754ff;
  color: var(--color);
}

.help-detail-page-box {
  display: flex;
  width: 100%;
}
.help-detail-page-box .notices-details-page-box {
  flex: 1 1;
}

.view-card-box {
  display: flex;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  padding: 10px 0;
}
.view-card-box .title-box {
  width: 90px;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
}
.view-card-box .title-box .txt:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background: #DDDDDD;
  background: var(--border-deep);
  border-radius: 50%;
  margin-right: 4px;
}
.view-card-box .main-box {
  padding-left: 1em;
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  flex: 1 1;
}
.view-card-box .main-box.hint {
  color: #4754ff;
  color: var(--font-high);
}
.view-card-box .main-box .img-box {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  height: 100px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  line-height: 98px;
}
.view-card-box .main-box .img-box img {
  max-width: 100%;
  max-height: 100%;
}

.invoice-list-page {
  padding: 16px;
}
.invoice-list-page .breadcrumb-block-box {
  margin-left: -220px;
  margin-bottom: 12px;
}
.invoice-list-page .invoice-list-page-main {
  background-color: #fff;
}
.invoice-list-page .invoice-list-page-main .tabs-box .tab {
  display: inline-block;
  vertical-align: middle;
  margin: 0 14px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.invoice-list-page .invoice-list-page-main .tabs-box .tab:hover {
  color: #4754ff;
  color: var(--color);
}
.invoice-list-page .invoice-list-page-main .tabs-box .tab.active {
  color: #4754ff;
  color: var(--color);
  border-color: #4754ff;
  border-color: var(--color);
}
.invoice-list-page .invoice-list-table-wrap {
  padding: 16px;
}
.invoice-list-page .invoice-list-table-wrap .cont-table {
  min-height: 320px;
  margin-bottom: 24px;
}
.invoice-list-page .invoice-list-table-wrap .ant-empty {
  margin-top: 32px;
}

.invoice-detail-des {
  padding: 8px 12px;
  background: #FAFAFA;
}
.invoice-detail-des .head {
  font-size: 16px;
  font-weight: 700;
}
.invoice-detail-des .head .label {
  color: #666;
  color: var(--font-normal);
}
.invoice-detail-des .head .value {
  color: #000;
  color: var(--font-title);
}
.invoice-detail-des .row {
  margin-top: 6px;
}
.invoice-detail-des .row .label {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
}
.invoice-detail-des .row .value {
  display: inline-block;
  min-width: 130px;
  margin-right: 24px;
  color: #000;
  color: var(--font-title);
}

.invoice-detail-attachment {
  padding: 24px;
}

.coupon-list-page {
  position: relative;
  margin: 16px 0;
}
.coupon-list-page .breadcrumb-block-box {
  margin-left: -220px;
}
.coupon-list-page .coupon-center-btn {
  position: absolute;
  right: 16px;
  top: 50px;
  color: #FF860C;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 6px 16px;
  border-radius: 100px;
  background: rgba(255, 134, 12, 0.1);
  z-index: 2;
}
.coupon-list-page .coupon-list-tabs {
  margin-top: 8px;
  padding: 0 14px;
  background-color: #fff;
}
.coupon-list-page .coupon-list-tabs .coupon-list-tabpane {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 400px;
}
.coupon-list-page .coupon-list-tabs .coupon-list-tabpane .coupon-list-card:nth-child(4n) {
  margin-right: 0;
}
.coupon-list-page .coupon-list-tabs .coupon-list-tabpane .ant-empty {
  display: block;
  margin: 16px auto;
}
.coupon-list-page .coupon-list-tabs .ant-tabs-extra-content {
  margin-right: 20px;
}
.coupon-list-page .ant-pagination {
  padding: 24px 0;
  background-color: #fff;
  text-align: center;
}

.coupon-wrap .coupon-list-card {
  width: 220px;
  height: 280px;
  margin-right: 29px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  background-color: #F7F8FA;
}
.coupon-wrap .coupon-list-card .info-box {
  position: relative;
  height: 120px;
  padding: 16px;
  color: #fff;
  text-align: center;
  background: linear-gradient(110deg, #F23434 0%, #FC846A 100%);
}
.coupon-wrap .coupon-list-card .info-box .price {
  font-size: 36px;
  line-height: 1;
}
.coupon-wrap .coupon-list-card .info-box .price:first-letter {
  font-size: 24px;
}
.coupon-wrap .coupon-list-card .info-box .price .target {
  font-size: 16px;
  margin-left: 8px;
}
.coupon-wrap .coupon-list-card .info-box .name {
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0;
}
.coupon-wrap .coupon-list-card .info-box .time {
  font-size: 14px;
  opacity: 0.85;
}
.coupon-wrap .coupon-list-card .info-box .whiteCircle {
  transform: translate(-10px, -4px);
}
.coupon-wrap .coupon-list-card .info-box .tag {
  position: absolute;
  right: 0px;
  top: 0;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 0 0 0 6px;
  color: #89660B;
  background-color: #FFDD66;
}
.coupon-wrap .coupon-list-card .info-box .tag_remain {
  position: absolute;
  top: 2px;
  left: 4px;
}
.coupon-wrap .coupon-list-card .info-box .status-icon {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.coupon-wrap .coupon-list-card .intro-box {
  margin-top: -1px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  border-radius: 0 4px 4px 0;
  height: 160px;
  padding: 16px 0;
}
.coupon-wrap .coupon-list-card .explain-box {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin: 16px 0;
  max-height: 122px;
  min-height: 74px;
  cursor: pointer;
}
.coupon-wrap .coupon-list-card .explain-box .label {
  color: #000;
  color: var(--font-title);
}
.coupon-wrap .coupon-list-card .explain-box .introduce {
  flex: 1 1;
  color: #666;
  color: var(--font-normal);
}
.coupon-wrap .coupon-list-card .explain-box .iconfont {
  font-size: 14px;
}
.coupon-wrap .coupon-list-card .coupon-btn {
  width: 100px;
  height: 36px;
  line-height: 20px;
  padding: 8px 0;
  position: absolute;
  transition: all 0.3s;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #4754ff;
  border: 1px solid var(--color);
  color: #4754ff;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}
.coupon-wrap .coupon-list-card-used .info-box,
.coupon-wrap .coupon-list-card-expires .info-box {
  position: relative;
  padding: 16px;
  color: #000;
  background: linear-gradient(110deg, #E3E3E3 0%, #E3E3E3 100%);
}
.coupon-wrap .coupon-list-card-used .info-box .tag,
.coupon-wrap .coupon-list-card-expires .info-box .tag {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.45);
}

.coupon-center-page .headline {
  text-align: center;
  height: 114px;
  line-height: 114px;
  background-color: #fff;
}
.coupon-center-page .headline .icon-title-1 {
  width: 316px;
  height: 40px;
}
.coupon-center-page .coupon-center-main {
  width: 1200px;
  margin: 8px auto;
  padding: 24px;
  background-color: #fff;
}
.coupon-center-page .coupon-center-main .ant-pagination {
  padding-top: 24px;
  text-align: center;
}
.coupon-center-page .coupon-center-list {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(5, 1fr);
}
.coupon-center-page .coupon-center-card {
  width: 374px;
  display: flex;
  margin-bottom: 16px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
}
.coupon-center-page .coupon-center-card:nth-child(3n) {
  margin-right: 0;
}
.coupon-center-page .coupon-center-card .price-box {
  width: 120px;
  height: 108px;
  padding-top: 32px;
  text-align: center;
  color: #fff;
  background: radial-gradient(circle at left center, transparent 3px, #FF3838 0) left top/4px 12px repeat-y, linear-gradient(to left, #FF6969, #FF3838) 3px/118px no-repeat;
}
.coupon-center-page .coupon-center-card .price-box .money {
  font-size: 24px;
  line-height: 1;
}
.coupon-center-page .coupon-center-card .price-box .money:first-letter {
  font-size: 12px;
}
.coupon-center-page .coupon-center-card .price-box .target {
  line-height: 1;
  font-size: 12px;
  margin-top: 8px;
}
.coupon-center-page .coupon-center-card .info-box {
  display: flex;
  width: 255px;
  height: 108px;
  padding: 16px 0;
  border-radius: 0 2px 2px 0;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(227, 227, 227);
}
.coupon-center-page .coupon-center-card .intro-box {
  width: 162px;
  padding: 0 12px 0 16px;
  border-right: 1px dashed #DDDDDD;
  border-right: 1px dashed var(--border-deep);
}
.coupon-center-page .coupon-center-card .intro-box .tag {
  display: inline-block;
  padding: 0 8px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 49px;
  background-color: #4754ff;
  background-color: var(--color-hint);
  color: #fff;
}
.coupon-center-page .coupon-center-card .intro-box .name {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  font-weight: 600;
}
.coupon-center-page .coupon-center-card .intro-box .time {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
}
.coupon-center-page .coupon-center-card .status-box {
  width: 92px;
  text-align: center;
}
.coupon-center-page .coupon-center-card .btn-box {
  text-align: center;
}
.coupon-center-page .coupon-center-card .btn-box .btn {
  font-size: 12px;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 11px;
  border: 0 none;
  background-color: #4754ff;
  background-color: var(--color-hint);
  color: #fff;
}
.coupon-center-page .coupon-list-card-used .coupon-btn,
.coupon-center-page .coupon-list-card-used .coupon-btn {
  display: none;
}
.coupon-center-page .coupon-card-use .price-box {
  background: rgb(227, 227, 227);
  color: #000;
}
.coupon-center-page .coupon-card-use .price-box .target {
  opacity: 0.65;
}
.coupon-center-page .coupon-card-use .info-box .tag {
  color: #666;
  color: var(--font-normal);
  background-color: rgb(227, 227, 227);
}

.experience-add-box {
  width: 100%;
  background: #fff;
}

.experience-add-body {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  background: url(/assets/media/map.a7cbff11.png) no-repeat center/530px 260px;
  text-align: center;
}
.experience-add-body h2 {
  padding-top: 100px;
  font-size: 24px;
  color: #4754ff;
  color: var(--color);
}
.experience-add-body .experience-add {
  padding-top: 22px;
}
.experience-add-body .experience-add .select-box {
  display: inline-block;
  vertical-align: middle;
  width: 240px;
  padding: 0;
  margin: 0 20px;
}
.experience-add-body .experience-add .search-size-max {
  height: 40px;
  line-height: 40px;
}
.experience-add-body .experience-add .dis-inb:last-child .search-size-max {
  width: 260px;
}
.experience-add-body .experience-add .dis-inb:last-child .txt {
  padding-right: 20px;
}

.experience-list-box {
  width: 1200px;
  margin: 0 auto;
  font-size: 0;
  padding-top: 16px;
}
.experience-list-box .null-tips-box {
  height: 300px;
  text-align: center;
  padding-top: 120px;
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
}

.experience-card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 16px;
  background: #fff;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  width: calc(50% - 8px);
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 0;
  cursor: pointer;
}
.experience-card:nth-child(2n) {
  margin-right: 0;
}
.experience-card h2 {
  font-size: 16px;
  color: #000;
  color: var(--font-title);
  margin-bottom: 12px;
  cursor: pointer;
  min-height: 40px;
}
.experience-card h2:hover {
  color: #4754ff;
  color: var(--color);
}
.experience-card h2 .iconfont {
  margin-right: 4px;
}
.experience-card .img-box {
  width: 160px;
  height: 160px;
  display: inline-block;
  vertical-align: top;
}
.experience-card .img-box img {
  width: 160px;
  height: 160px;
}
.experience-card .txt-box {
  display: inline-block;
  width: calc(100% - 170px);
  vertical-align: top;
  padding: 12px 0 0 24px;
}
.experience-card .txt-box dt {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  font-weight: bold;
  line-height: 1.3;
  padding-bottom: 12px;
  cursor: pointer;
}
.experience-card .txt-box dt:hover {
  color: #4754ff;
  color: var(--color);
}
.experience-card .txt-box dd {
  font-size: 12px;
  color: #999;
  color: var(--font-tips);
  line-height: 1.3;
  padding-bottom: 12px;
}
.experience-card .experience-btn {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 149px;
  height: 34px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #4754ff;
  border: solid 1px var(--color);
  line-height: 34px;
  text-align: center;
  font-size: 14px;
  color: #4754ff;
  color: var(--color);
  cursor: pointer;
}

.experience-detail-box {
  width: 1200px;
  margin: 0 auto;
}

.experience-detail-head {
  margin: 20px 0;
  font-size: 0;
  height: 420px;
}
.experience-detail-head .experience-info {
  display: inline-block;
  vertical-align: top;
  width: 450px;
  height: 100%;
  background: #fff;
  padding: 18px 0 26px;
  text-align: center;
}
.experience-detail-head .experience-info h4 {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  font-weight: bold;
  line-height: 1em;
  padding-bottom: 24px;
}
.experience-detail-head .experience-info .code-box {
  display: inline-block;
  width: 180px;
  height: 180px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  margin-bottom: 32px;
  padding: 4px;
}
.experience-detail-head .experience-info p {
  font-size: 12px;
  color: #000;
  color: var(--font-title);
  padding-bottom: 8px;
  line-height: 1em;
}
.experience-detail-head .experience-info .experience-btn {
  margin-top: 26px;
  display: inline-block;
  width: 149px;
  height: 34px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #4754ff;
  border: solid 1px var(--color);
  line-height: 34px;
  text-align: center;
  font-size: 14px;
  color: #4754ff;
  color: var(--color);
  cursor: pointer;
}
.experience-detail-head .experience-banner {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 450px);
  height: 420px;
  background: #fff;
  position: relative;
}
.experience-detail-head .experience-banner .ant-carousel {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.experience-detail-head .experience-banner .ant-carousel .slick-slide {
  text-align: center;
  height: 420px;
  line-height: 420px;
  overflow: hidden;
}
.experience-detail-head .experience-banner .ant-carousel .slick-dots li {
  overflow: hidden;
  border-radius: 4px;
}
.experience-detail-head .experience-banner .ant-carousel .slick-dots li.slick-active button {
  background: #4754ff;
  background: var(--color);
}
.experience-detail-head .experience-banner .img-box {
  width: 100%;
  height: 420px;
  position: relative;
}
.experience-detail-head .experience-banner .img-box .img-wrap {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: none;
}
.experience-detail-head .experience-banner .img-box .img-wrap img {
  width: 100%;
  height: 100%;
}

.dis-inb {
  display: inline-block;
}

.pop-experience .experience-body {
  text-align: center;
}
.pop-experience .experience-body h4 {
  font-size: 14px;
  color: #000;
  color: var(--font-title);
  font-weight: bold;
  line-height: 1em;
  padding-bottom: 10px;
  text-align: center;
}
.pop-experience .experience-body .code {
  display: inline-block;
  width: 180px;
  height: 180px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  padding: 4px;
  margin-bottom: 18px;
}
.pop-experience .experience-body p {
  font-size: 12px;
  line-height: 1em;
  color: #000;
  color: var(--font-title);
  padding-bottom: 10px;
  text-align: center;
}

.experience-detail-body {
  background: #fff;
}
.experience-detail-body .experience-detail-body-title {
  font-size: 14px;
  font-weight: bold;
  padding: 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  position: relative;
}
.experience-detail-body .experience-detail-body-title:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 2px;
  height: 16px;
  background: #000;
  background: var(--font-title);
  transform: translateY(-50%);
}

.video-box {
  padding: 16px 0;
  text-align: center;
}
.video-box .video-wrap {
  display: inline-block;
  width: 600px;
  height: 400px;
}
.video-box video {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.experience-introduce-box {
  padding: 24px;
}

.experience-page-footer {
  text-align: center;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #4754ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #707eff;
}
a:active {
  color: #3237d9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #4754ff;
}
::selection {
  color: #fff;
  background: #4754ff;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #4754ff;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #4754ff;
  box-shadow: 0 0 0 0 #4754ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #4754ff;
    box-shadow: 0 0 0 6px #4754ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}
.ant-alert-content {
  flex: 1 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #f0f3ff;
  border: 1px solid #c2ccff;
}
.ant-alert-info .ant-alert-icon {
  color: #4754ff;
}
.ant-alert-warning {
  background-color: #fff7e6;
  border: 1px solid #ffcf87;
}
.ant-alert-warning .ant-alert-icon {
  color: #FF860C;
}
.ant-alert-error {
  background-color: #ffeee6;
  border: 1px solid #ffa480;
}
.ant-alert-error .ant-alert-icon {
  color: #F43005;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description {
  padding-right: 24px;
  padding-left: 15px;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: transparent;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #4754ff;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.ant-anchor-ink-ball-visible {
  display: inline-block;
}
.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 4px 0 4px 16px;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #4754ff;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 4px 16px 4px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 2px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-count.anticon-spin,
.ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #F43005;
}
.ant-badge-status-warning {
  background-color: #FF860C;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #4754ff;
  border-radius: 2px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentcolor transparent transparent currentcolor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb li:last-child {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.65);
}
li:last-child > .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #707eff;
  border-color: #707eff;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #3237d9;
  border-color: #3237d9;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #4754ff;
  background: #4754ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #707eff;
  background: #707eff;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #3237d9;
  background: #3237d9;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #707eff;
  border-left-color: #707eff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #707eff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #707eff;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  border-color: #d9d9d9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #707eff;
  border-color: #707eff;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #3237d9;
  border-color: #3237d9;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #707eff;
  border-color: #707eff;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #3237d9;
  border-color: #3237d9;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #F43005;
  background: #F43005;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff5b2e;
  background: #ff5b2e;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #cf1f00;
  background: #cf1f00;
}
.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #4754ff;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #707eff;
  border-color: #707eff;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #3237d9;
  border-color: #3237d9;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.65);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #707eff;
  border-color: #707eff;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #3237d9;
  border-color: #3237d9;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #F43005;
  border-color: #F43005;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff5b2e;
  border-color: #ff5b2e;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #cf1f00;
  border-color: #cf1f00;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #F43005;
  background: #F43005;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff5b2e;
  background: #ff5b2e;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #cf1f00;
  background: #cf1f00;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #F43005;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #707eff;
  border-color: #707eff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #3237d9;
  border-color: #3237d9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff5b2e;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #cf1f00;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #F43005;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #707eff;
  border-color: #707eff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #3237d9;
  border-color: #3237d9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff5b2e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #cf1f00;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}
.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #707eff;
  border-color: #707eff;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #3237d9;
  border-color: #3237d9;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #4754ff;
  border-color: #4754ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #707eff;
  border-color: #707eff;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #3237d9;
  border-color: #3237d9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #F43005;
  border-color: #F43005;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff5b2e;
  border-color: #ff5b2e;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #cf1f00;
  border-color: #cf1f00;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #F43005;
  border-color: #F43005;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff5b2e;
  border-color: #ff5b2e;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #cf1f00;
  border-color: #cf1f00;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #F43005;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff5b2e;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #cf1f00;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}
a.ant-btn-disabled {
  cursor: not-allowed;
}
a.ant-btn-disabled > * {
  pointer-events: none;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.ant-btn-disabled > a:only-child,
a.ant-btn-disabled:hover > a:only-child,
a.ant-btn-disabled:focus > a:only-child,
a.ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.ant-btn-disabled > a:only-child::after,
a.ant-btn-disabled:hover > a:only-child::after,
a.ant-btn-disabled:focus > a:only-child::after,
a.ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: -1px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
  margin-left: -1px;
}
.ant-btn-compact-item:hover,
.ant-btn-compact-item:focus,
.ant-btn-compact-item:active {
  z-index: 2;
}
.ant-btn-compact-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
  border-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100% + 1px * 2);
  background-color: #707eff;
  content: ' ';
}
.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]) + .ant-btn-compact-item-rtl.ant-btn-primary:not([disabled])::after {
  right: -1px;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}
.ant-btn-compact-vertical-item:hover,
.ant-btn-compact-vertical-item:focus,
.ant-btn-compact-vertical-item:active {
  z-index: 2;
}
.ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item:not(.ant-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item:not(.ant-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 1px * 2);
  height: 1px;
  background-color: #707eff;
  content: ' ';
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #707eff;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #707eff;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 2px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-mini .ant-picker-cell::before {
  pointer-events: none;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #f0f3ff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #4754ff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #4754ff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.65);
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid .ant-card-body {
  display: flex;
  flex-wrap: wrap;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #4754ff;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #4754ff;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  display: flex;
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  flex: 1 1;
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li button::after {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  content: '';
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 0;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-cascader-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #4754ff;
}
.ant-cascader-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4754ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-cascader-checkbox:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox::after {
  visibility: visible;
}
.ant-cascader-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-cascader-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #4754ff;
  border-color: #4754ff;
}
.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-cascader-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled::after {
  visibility: hidden;
}
.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-cascader-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-cascader-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}
.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #4754ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-checkbox-rtl {
  direction: rtl;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 8px;
}
.ant-cascader {
  width: 184px;
}
.ant-cascader-checkbox {
  top: 0;
  margin-right: 8px;
}
.ant-cascader-menus {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}
.ant-cascader-menu {
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: 0;
  margin: -4px 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #f0f3ff;
}
.ant-cascader-menu-item-content {
  flex: auto;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  margin-right: -1px;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item).ant-cascader-compact-item-rtl {
  margin-left: -1px;
}
.ant-cascader-compact-item:hover,
.ant-cascader-compact-item:focus,
.ant-cascader-compact-item:active {
  z-index: 2;
}
.ant-cascader-compact-item[disabled] {
  z-index: 0;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-last-item).ant-cascader {
  border-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-rtl .ant-cascader-menu-item-expand-icon,
.ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-cascader-rtl .ant-cascader-checkbox {
  top: 0;
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #4754ff;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4754ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4754ff;
  border-color: #4754ff;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #4754ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  flex: none;
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #F43005;
}
.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff5b2e;
  box-shadow: 0 0 0 2px rgba(244, 48, 5, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff5b2e;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #FF860C;
}
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffa136;
  box-shadow: 0 0 0 2px rgba(255, 134, 12, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffa136;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-picker-input > input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 0px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 0px 7px 0px;
}
.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #4754ff;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #4754ff;
  background: #f0f3ff;
  border-color: #c2ccff;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  margin-right: -1px;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item).ant-picker-compact-item-rtl {
  margin-left: -1px;
}
.ant-picker-compact-item:hover,
.ant-picker-compact-item:focus,
.ant-picker-compact-item:active {
  z-index: 2;
}
.ant-picker-compact-item.ant-picker-focused {
  z-index: 2;
}
.ant-picker-compact-item[disabled] {
  z-index: 0;
}
.ant-picker-compact-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-last-item).ant-picker {
  border-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-first-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-last-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-first-item:not(.ant-picker-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-last-item:not(.ant-picker-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #4754ff;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #4754ff;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.65);
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #4754ff;
  border-radius: 2px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #f0f3ff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #4754ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #f0f3ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #adb3ff;
  border-bottom: 1px dashed #adb3ff;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #f9faff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #f9faff;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #adb3ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #adb3ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #4754ff;
}
.ant-picker-today-btn:hover {
  color: #707eff;
}
.ant-picker-today-btn:active {
  color: #3237d9;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #adb3ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #adb3ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #adb3ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #adb3ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #4754ff;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(240, 243, 255, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #f0f3ff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #adb3ff;
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #adb3ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #adb3ff;
  border-left: 1px dashed #adb3ff;
  border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  border-radius: 2px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1 1;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.ant-drawer-inline {
  position: absolute;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.ant-drawer-content-wrapper-hidden {
  display: none;
}
.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ant-drawer-header {
  display: flex;
  flex: 0 1;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.ant-drawer-extra {
  flex: none;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-title {
  flex: 1 1;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-body {
  flex: 1 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active,
.ant-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter,
.ant-drawer-mask-motion-appear {
  opacity: 0;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active {
  opacity: 1;
}
.ant-drawer-mask-motion-leave {
  opacity: 1;
}
.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start,
.ant-drawer-panel-motion-left-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active,
.ant-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start,
.ant-drawer-panel-motion-right-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active,
.ant-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start,
.ant-drawer-panel-motion-top-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active,
.ant-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start,
.ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active,
.ant-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #F43005;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #F43005;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-top,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-dropdown-placement-top > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #4754ff;
  background-color: #f0f3ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #4754ff;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #4754ff;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: 0;
}
.ant-form-horizontal .ant-form-item-label[class$='-24'] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*='-24 '] + .ant-form-item-control {
  min-width: unset;
}
.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #F43005;
}
.ant-form-item-explain-warning {
  color: #FF860C;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #FF860C;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #F43005;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item-with-help {
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-top: 0px;
}
.ant-form-item-explain-connected {
  width: 100%;
}
.ant-form-item-extra {
  min-height: 24px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
}
.ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-feedback-icon-success {
  color: #52c41a;
}
.ant-form-item-feedback-icon-error {
  color: #F43005;
}
.ant-form-item-feedback-icon-warning {
  color: #FF860C;
}
.ant-form-item-feedback-icon-validating {
  color: #4754ff;
}
.ant-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-appear,
.ant-show-help-enter {
  opacity: 0;
}
.ant-show-help-appear-active,
.ant-show-help-enter-active {
  opacity: 1;
}
.ant-show-help-leave {
  opacity: 1;
}
.ant-show-help-leave-active {
  opacity: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-space-evenly {
  justify-content: space-evenly;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1081;
  width: 100%;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-image-preview-operations-operation:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: fixed;
  top: 50%;
  right: 8px;
  z-index: 1081;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s;
  pointer-events: auto;
}
.ant-image-preview-switch-left:hover,
.ant-image-preview-switch-right:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled,
.ant-image-preview-switch-left-disabled:hover,
.ant-image-preview-switch-right-disabled:hover {
  color: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon,
.ant-image-preview-switch-left-disabled:hover > .anticon,
.ant-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 8px;
}
.ant-image-preview-switch-right {
  right: 8px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #707eff;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: rgba(255, 255, 255, 0);
}
.ant-input-affix-wrapper > .ant-input {
  font-size: inherit;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > .ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
}
.ant-input-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix > *:not(:last-child),
.ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.anticon.ant-input-clear-icon:active,
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #F43005;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff5b2e;
  box-shadow: 0 0 0 2px rgba(244, 48, 5, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #F43005;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #FF860C;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffa136;
  box-shadow: 0 0 0 2px rgba(255, 134, 12, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #FF860C;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #F43005;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff5b2e;
  box-shadow: 0 0 0 2px rgba(244, 48, 5, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #F43005;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #FF860C;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffa136;
  box-shadow: 0 0 0 2px rgba(255, 134, 12, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #FF860C;
}
.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #F43005;
  border-color: #F43005;
}
.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #FF860C;
  border-color: #FF860C;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 0px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #4754ff;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-input-number-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon.anticon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 32px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-input-compact-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-compact-item:hover,
.ant-input-compact-item:focus,
.ant-input-compact-item:active {
  z-index: 2;
}
.ant-input-compact-item[disabled] {
  z-index: 0;
}
.ant-input-compact-item:not(.ant-input-compact-first-item):not(.ant-input-compact-last-item).ant-input {
  border-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-first-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-last-item:not(.ant-input-compact-first-item):not(.ant-input-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-first-item:not(.ant-input-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-last-item:not(.ant-input-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #707eff;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #707eff;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-item-rtl):not(.ant-input-compact-last-item) .ant-input-group-addon .ant-input-search-button {
  margin-right: -1px;
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input,
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:hover,
.ant-input-search.ant-input-compact-item > .ant-input:hover,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:hover,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:focus,
.ant-input-search.ant-input-compact-item > .ant-input:focus,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:focus,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:active,
.ant-input-search.ant-input-compact-item > .ant-input:active,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:active {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item > .ant-input-affix-wrapper-focused {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item-rtl:not(.ant-input-compact-last-item) .ant-input-group-addon:last-child .ant-input-search-button {
  margin-left: -1px;
  border-radius: 0;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover,
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover {
  border-left-color: #707eff;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #707eff;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  padding-inline-start: 11px;
}
.ant-input-number-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-input-number-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-affix-wrapper:focus,
.ant-input-rtl .ant-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper-borderless,
.ant-input-number-affix-wrapper-borderless:hover,
.ant-input-number-affix-wrapper-borderless:focus,
.ant-input-number-affix-wrapper-borderless-focused,
.ant-input-number-affix-wrapper-borderless-disabled,
.ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: #707eff;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: transparent;
}
.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}
.ant-input-number-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-input-number-prefix,
.ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.ant-input-number-prefix {
  margin-inline-end: 4px;
}
.ant-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #F43005;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff5b2e;
  box-shadow: 0 0 0 2px rgba(244, 48, 5, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #F43005;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #FF860C;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffa136;
  box-shadow: 0 0 0 2px rgba(255, 134, 12, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #FF860C;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #F43005;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff5b2e;
  box-shadow: 0 0 0 2px rgba(244, 48, 5, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #F43005;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #FF860C;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffa136;
  box-shadow: 0 0 0 2px rgba(255, 134, 12, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #FF860C;
}
.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #F43005;
  border-color: #F43005;
}
.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #FF860C;
  border-color: #FF860C;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 0px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap,
.ant-input-number-group > .ant-input-number {
  display: table-cell;
}
.ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-number-group-wrap > * {
  display: block !important;
}
.ant-input-number-group .ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}
.ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-number-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #4754ff;
}
.ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group-addon:first-child {
  border-right: 0;
}
.ant-input-number-group-addon:last-child {
  border-left: 0;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 0px 7px;
}
.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group > .ant-input-number-rtl:first-child,
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-rtl.ant-input-number-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #707eff;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -webkit-appearance: textfield !important;
     -moz-appearance: textfield !important;
          appearance: textfield !important;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down,
.ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #F43005;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item).ant-input-number-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-number-compact-item:hover,
.ant-input-number-compact-item:focus,
.ant-input-number-compact-item:active {
  z-index: 2;
}
.ant-input-number-compact-item.ant-input-number-focused {
  z-index: 2;
}
.ant-input-number-compact-item[disabled] {
  z-index: 0;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-last-item).ant-input-number {
  border-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #4754ff;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #F43005;
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ff5b2e;
  box-shadow: 0 0 0 2px rgba(244, 48, 5, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-error .ant-input-prefix {
  color: #F43005;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #FF860C;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ffa136;
  box-shadow: 0 0 0 2px rgba(255, 134, 12, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-warning .ant-input-prefix {
  color: #FF860C;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-mentions::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-mentions:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 0px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
    -o-tab-size: inherit;
       tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-mentions > textarea:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #F43005;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #F43005;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #F43005;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #F43005;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #F43005;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #F43005;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #F43005;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #ebefff;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #4754ff;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #f0f3ff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item a:hover {
  color: #4754ff;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > .ant-badge a:hover {
  color: #4754ff;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #4754ff;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #4754ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0f3ff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #ebefff;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #4754ff;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #4754ff;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #4754ff;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #4754ff;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #4754ff;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #4754ff;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #4754ff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #4754ff;
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #3237d9;
}
.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #3237d9;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #4754ff;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #4754ff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #4754ff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #F43005;
}
.ant-message-warning .anticon {
  color: #FF860C;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #4754ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #F43005;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #FF860C;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #4754ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-modal-confirm .ant-zoom-leave .ant-modal-confirm-btns {
  pointer-events: none;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: auto;
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-top .ant-notification-notice,
.ant-notification-bottom .ant-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #4754ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #FF860C;
}
.anticon.ant-notification-notice-icon-error {
  color: #F43005;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  opacity: 0;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-notification-top,
.ant-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}
.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #4754ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #000;
}
.ant-page-header-back-button:focus-visible,
.ant-page-header-back-button:hover {
  color: #707eff;
}
.ant-page-header-back-button:active {
  color: #3237d9;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  white-space: unset;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: #4754ff;
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: #4754ff;
}
.ant-pagination-item:focus-visible {
  border-color: #4754ff;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: #4754ff;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #4754ff;
}
.ant-pagination-item-active a {
  color: #4754ff;
}
.ant-pagination-item-active:hover {
  border-color: #707eff;
}
.ant-pagination-item-active:focus-visible {
  border-color: #707eff;
}
.ant-pagination-item-active:hover a {
  color: #707eff;
}
.ant-pagination-item-active:focus-visible a {
  color: #707eff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #4754ff;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #707eff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: #4754ff;
  border-color: #4754ff;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #4754ff;
  border-color: #4754ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  -moz-user-select: none;
       user-select: none;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #4754ff;
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-mini .ant-pagination-total-text,
.ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  max-width: 100vw;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-message {
  display: flex;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-popover-message-icon {
  display: inline-block;
  margin-right: 8px;
  color: #FF860C;
  font-size: 14px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #F43005;
}
.ant-progress-status-exception .ant-progress-text {
  color: #F43005;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #F43005;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #F43005;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #4754ff;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(71, 84, 255, 0.12);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4754ff;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #4754ff;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #4754ff;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #4754ff;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(71, 84, 255, 0.12);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #4754ff;
  background: #fff;
  border-color: #4754ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #4754ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #4754ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #707eff;
  border-color: #707eff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #707eff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #3237d9;
  border-color: #3237d9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #3237d9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(71, 84, 255, 0.12);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #4754ff;
  border-color: #4754ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #707eff;
  border-color: #707eff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #3237d9;
  border-color: #3237d9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(71, 84, 255, 0.12);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #707eff;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #F43005;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #4754ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #FF860C;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}
.ant-segmented.ant-segmented-block {
  display: flex;
}
.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1 1;
  min-width: 0;
}
.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: rgba(0, 0, 0, 0.06);
}
.ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #262626;
}
.ant-segmented-item:hover,
.ant-segmented-item:focus {
  color: #262626;
}
.ant-segmented-item-label {
  min-height: 28px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented-item-icon + * {
  margin-left: 6px;
}
.ant-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}
.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}
.ant-segmented-item-disabled,
.ant-segmented-item-disabled:hover,
.ant-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}
.ant-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}
.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}
.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s, visibility 0s;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  visibility: hidden;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #F43005 !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ff5b2e;
  box-shadow: 0 0 0 2px rgba(244, 48, 5, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #FF860C !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ffa136;
  box-shadow: 0 0 0 2px rgba(255, 134, 12, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-flex;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-arrow > *:not(:last-child) {
  margin-inline-end: 8px;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #f0f3ff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #4754ff;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select.ant-select-in-form-item {
  width: 100%;
}
.ant-select-compact-item:not(.ant-select-compact-last-item) {
  margin-right: -1px;
}
.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
  margin-right: 0;
  margin-left: -1px;
}
.ant-select-compact-item:hover > *,
.ant-select-compact-item:focus > *,
.ant-select-compact-item:active > * {
  z-index: 2;
}
.ant-select-compact-item.ant-select-focused > * {
  z-index: 2;
}
.ant-select-compact-item[disabled] > * {
  z-index: 0;
}
.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
  border-radius: 0;
}
.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-first-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl:not(.ant-select-compact-last-item) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl:not(.ant-select-compact-first-item) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: auto;
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: auto;
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton-active .ant-skeleton-title::after,
.ant-skeleton-active .ant-skeleton-paragraph > li::after,
.ant-skeleton-active .ant-skeleton-avatar::after,
.ant-skeleton-active .ant-skeleton-button::after,
.ant-skeleton-active .ant-skeleton-input::after,
.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-square {
  width: 32px;
  min-width: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  margin-left: -2px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #c2ccff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #c2ccff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging {
  z-index: 1;
}
.ant-slider-handle:focus {
  border-color: #6c76ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(71, 84, 255, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #4754ff;
}
.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #99a7ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #99a7ff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-color: #a3aaff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-compact {
  display: inline-flex;
}
.ant-space-compact-block {
  display: flex;
  width: 100%;
}
.ant-space-compact-vertical {
  flex-direction: column;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-space-compact-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #4754ff;
  font-size: 0;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  font-size: 14px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #4754ff;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-skeleton {
  padding-top: 16px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: left;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #4754ff;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #4754ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #4754ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #4754ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #4754ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #4754ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #4754ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #4754ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #4754ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #4754ff;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #F43005;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #F43005;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #F43005;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #F43005;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #F43005;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #F43005;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #4754ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #4754ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #4754ff;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #4754ff;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 11px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #4754ff;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item {
  padding-right: 4px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 19px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-small.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 15px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-right: 4px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 4px;
  left: 19px;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child,
.ant-steps-with-progress.ant-steps-small.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-small > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 15px;
}
.ant-steps-with-progress.ant-steps-vertical .ant-steps-item {
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 14px !important;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #f0f3ff;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #4754ff;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #4754ff;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 40px;
}
.ant-table.ant-table-middle .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 40px;
}
.ant-table.ant-table-small .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 32px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f0f3ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #e6ebff;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 8px;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #4754ff;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1 1;
}
.ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #4754ff;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}
.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-filter-trigger.active {
  color: #4754ff;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #ebefff;
}
.ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #f0f0f0 solid;
}
.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table-selection-col {
  width: 32px;
}
.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: inline-flex;
  float: none;
  vertical-align: sub;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #4754ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transform: scale(0.94117647);
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus-visible,
.ant-table-row-expand-icon:hover {
  color: #707eff;
}
.ant-table-row-expand-icon:active {
  color: #3237d9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-left-all::after {
  display: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: calc(calc(2 + 1) + 1);
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-holder {
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.ant-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 2px 2px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item > span {
  flex: 1 1;
  white-space: nowrap;
}
.ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.ant-tabs-dropdown-menu-item-remove:hover {
  color: #707eff;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #4754ff;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #707eff;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #3237d9;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #4754ff;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #3237d9;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab:hover {
  color: #707eff;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4754ff;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-content {
  position: relative;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-tabpane {
  outline: none;
}
.ant-tabs-tabpane-hidden {
  display: none;
}
.ant-tabs-switch-appear,
.ant-tabs-switch-enter {
  transition: none;
}
.ant-tabs-switch-appear-start,
.ant-tabs-switch-enter-start {
  opacity: 0;
}
.ant-tabs-switch-appear-active,
.ant-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.ant-tabs-switch-leave {
  position: absolute;
  transition: none;
  inset: 0;
}
.ant-tabs-switch-leave-start {
  opacity: 1;
}
.ant-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #4754ff;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #4754ff;
}
.ant-tag-checkable:active {
  background-color: #3237d9;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #4754ff;
  background: #f0f3ff;
  border-color: #c2ccff;
}
.ant-tag-error {
  color: #F43005;
  background: #ffeee6;
  border-color: #ffa480;
}
.ant-tag-warning {
  color: #FF860C;
  background: #fff7e6;
  border-color: #ffcf87;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #4754ff;
  border-color: #4754ff;
}
.ant-timeline-item-head-red {
  color: #F43005;
  border-color: #F43005;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  width: -moz-max-content;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-content {
  position: relative;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer-status-error .ant-transfer-list {
  border-color: #F43005;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list {
  border-color: #FF860C;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-color: #707eff;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-color: #707eff;
  box-shadow: 0 0 0 2px rgba(71, 84, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search .anticon-search {
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  position: relative;
  color: #d9d9d9;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-transfer-list-content-item-remove:hover {
  color: #707eff;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #e6ebff;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #f0f3ff;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #4754ff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #4754ff;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4754ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #4754ff;
  border-color: #4754ff;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #4754ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #f0f3ff;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #4754ff;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-tree-treenode:hover .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  opacity: 0.45;
}
.ant-tree .ant-tree-treenode-draggable.ant-tree-treenode-disabled .ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #4754ff;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #ebefff;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #4754ff;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #4754ff;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #4754ff;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl .ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #4754ff;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4754ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #4754ff;
  border-color: #4754ff;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #4754ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #f0f3ff;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #4754ff;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-select-tree-treenode:hover .ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  opacity: 0.45;
}
.ant-select-tree .ant-select-tree-treenode-draggable.ant-select-tree-treenode-disabled .ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #4754ff;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #ebefff;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #4754ff;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #4754ff;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #4754ff;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: rgba(0, 0, 0, 0.65);
  word-break: break-word;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
  color: #52c41a;
}
.ant-typography.ant-typography-warning {
  color: #FF860C;
}
.ant-typography.ant-typography-danger {
  color: #F43005;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus {
  color: #cf1f00;
}
a.ant-typography.ant-typography-danger:hover {
  color: #ff5b2e;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2 > textarea,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3 > textarea,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4 > textarea,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5 > textarea,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
}
a.ant-typography,
.ant-typography a {
  color: #4754ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus-visible,
.ant-typography a:focus-visible,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #707eff;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #3237d9;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #4754ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus-visible,
.ant-typography-edit:focus-visible,
.ant-typography-copy:focus-visible,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #707eff;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #3237d9;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  height: 1em;
  margin: 0 !important;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-single-line {
  white-space: nowrap;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #4754ff;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #3237d9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #707eff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #707eff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 22.001px;
  line-height: 1;
  vertical-align: top;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-upload-list-item-card-actions:hover .anticon {
  color: rgba(0, 0, 0, 0.65);
}
.ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #F43005;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #F43005;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #F43005;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #ffeee6;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #F43005;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: forwards;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}

.user-menu-box {
  width: 200px;
  padding: 24px;
  margin-top: 68px;
  line-height: 1;
  color: var(--font-title);
  border: 1px solid #DCE0E5;
}
.user-menu-box dt {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  padding-top: 20px;
}
.user-menu-box dt:first-child {
  padding-top: 0;
}
.user-menu-box dd {
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
}
.user-menu-box dd::before {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 20px;
  border-radius: 0 3px 3px 0;
  background-color: transparent;
  transform: translate(-24px, -4px);
}
.user-menu-box dd:hover, .user-menu-box dd.active {
  color: var(--color);
}
.user-menu-box dd.active::before {
  background-color: var(--color);
}
.page-footer-box {
  width: 100%;
  background: #fff;
  position: relative;
}
.page-footer-box:hover .page-foot-edit-opt {
  display: flex;
}
.page-footer-box .page-foot-layout:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.page-footer-box .page-foot-layout .editor-box p {
  display: block;
}
.page-footer-box .page-foot-layout .editor-box a {
  display: inline-block;
}
.page-footer-box .shop-help-list-box {
  display: inline-flex;
  font-size: 12px;
}
.page-footer-box .shop-help-list-box .shop-help-list {
  flex: 1 1;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.page-footer-box .shop-help-list-box .shop-help-list .title {
  font-size: 16px;
  color: var(--font-title);
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 1;
}
.page-footer-box .shop-help-list-box .shop-help-list .txt {
  font-size: 12px;
  color: var(--font-normal);
  line-height: 24px;
  cursor: pointer;
}
.page-footer-box .shop-help-list-box .shop-help-list .txt:hover {
  color: var(--color);
}
.page-footer-box .shop-help-list-box .shop-help-list.fixedWidth {
  flex: auto;
  max-width: 220px;
}
.page-footer-box .wrap {
  position: relative;
  z-index: 2;
}
.page-footer-box .page-foot-background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.page-footer-box .page-foot-background img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: none;
}
.FooterCompanyInfo .footerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FooterCompanyInfo .footerTop .left-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.FooterCompanyInfo .footerTop .footetLogo {
  margin-right: 24px;
}
.FooterCompanyInfo .footerTop .footerTel {
  color: #121212;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.5px;
}
.FooterCompanyInfo .footerTop .right-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.FooterCompanyInfo .footerTop .shopItem {
  width: 36px;
  height: 36px;
  margin-left: 20px;
}
.FooterCompanyInfo .copyrightWrap {
  border-top: 1px solid #C2C7D1;
  padding: 12px 0;
  color: #595959;
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.5px;
  text-align: center;
}
.FooterCompanyInfo .copyrightWrap a {
  display: inline;
}
.page-head-wrap-fix {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
}
.page-head-wrap-fix.opacity-1 {
  background-color: #fff;
  border-bottom: 2px solid #000;
}

.opacity-0 {
  z-index: -1;
  opacity: 0;
}

.opacity-1 {
  z-index: 2;
  opacity: 1;
}

.header-banner-box {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.header-banner-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}

.shop-head-user-box {
  height: 32px;
  background: #000;
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid #e1e1e5;
  font-family: PingFang SC;
}
.shop-head-user-box .main-box {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.shop-head-user-box .head-welcome-txt {
  font-size: 12px;
  padding-left: 24px;
  line-height: 32px;
}
.shop-head-user-box .head-user-nav-box {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}
.shop-head-user-box .head-user-nav-box .ant-popover-inner-content {
  padding: 0 !important;
}
.shop-head-user-box .head-user-nav-box p {
  margin-bottom: 0;
}
.shop-head-user-box .head-user-nav-box .nav-card {
  margin-left: 16px;
  cursor: pointer;
  font-size: 12px;
}

.nav-card-main {
  display: flex;
  padding: 16px;
}
.nav-card-main .qr-code-box {
  text-align: center;
  margin: 0 12px;
}
.nav-card-main .qr-code-box p {
  margin-bottom: 0;
}
.nav-card-main .qr-code-box .code-box {
  display: inline-block;
  width: 104px;
  height: 104px;
}
.nav-card-main .qr-code-box .code-box img {
  width: 100%;
  height: 100%;
}

.user-nav-card-box .user-nav-card {
  width: 160px;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid var(--border-deep);
  cursor: pointer;
  color: var(--font-normal);
  padding: 0 12px;
}
.user-nav-card-box .user-nav-card:hover {
  color: var(--color);
}

.head-main-box {
  position: relative;
}
.head-main-box .line-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: var(--font-title);
}
.head-main-box .main-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 78px;
}
.head-main-box .main-wrap .logo-box {
  width: 107px;
  height: 32px;
}
.head-main-box .main-wrap .logo-box img {
  width: 100%;
  height: 100%;
}
.head-main-box .main-wrap .search-wrap .cart-box {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 20px;
  height: 36px;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: 1px solid var(--font-title);
  padding: 0 12px;
  border-radius: 8px;
  min-width: 96px;
  cursor: pointer;
}
.head-main-box .main-wrap .search-wrap .cart-box i {
  margin-right: 6px;
}
.head-main-box .main-wrap .search-box {
  width: 400px;
  height: 36px;
  line-height: 36px;
  vertical-align: middle;
  display: inline-flex;
  align-content: center;
  align-items: center;
  border: 1px solid var(--font-title);
  position: relative;
  border-radius: 8px;
}
.head-main-box .main-wrap .search-box .txt {
  flex: 1 1;
  height: 30px;
  line-height: 30px;
  border: 0 none;
  font-size: 14px;
}
.head-main-box .main-wrap .search-box .search-btn {
  width: 54px;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: var(--font-title);
  color: #fff;
  cursor: pointer;
}
.head-main-box .main-wrap .search-box .search-btn:hover {
  opacity: 0.8;
}
.head-main-box .main-wrap .search-box .dropdown-box {
  position: absolute;
  top: 34px;
  left: -2px;
  right: -2px;
  background: #fff;
  z-index: 1;
}

.search-log-list-box {
  border: 1px solid var(--color);
  background: #fff;
  max-height: 300px;
  display: flex;
  flex-direction: column;
}
.search-log-list-box .keyword-foot-box {
  width: 100%;
  height: 34px;
  line-height: 34px;
  padding: 0 12px;
  text-align: right;
  border-top: 1px solid var(--border-deep);
}
.search-log-list-box .keyword-foot-box .txt {
  font-size: 14px;
  color: var(--font-normal);
  cursor: pointer;
}
.search-log-list-box .keyword-foot-box .txt:hover {
  color: var(--color);
}
.search-log-list-box .logo-box {
  flex: 1 1;
  height: 0;
  overflow: scroll;
  width: 100%;
}
.search-log-list-box .log-item {
  height: 24px;
  line-height: 24px;
  padding: 0 12px;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: var(--color);
  font-size: 12px;
  margin: 0 !important;
}
.search-log-list-box .log-item .tips {
  color: var(--font-tips);
}
.search-log-list-box .log-item .ope {
  position: relative;
  z-index: 2;
}
.search-log-list-box .log-item .del {
  display: none;
}
.search-log-list-box .log-item:hover {
  background: #fff;
}
.search-log-list-box .log-item:hover .tips {
  display: none;
}
.search-log-list-box .log-item:hover .del {
  display: block;
}
.search-log-list-box .log-item:hover:after {
  content: "";
  background: var(--color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  z-index: 0;
}
.search-log-list-box .log-item .txt {
  flex: 1 1;
  width: 0;
  position: relative;
  z-index: 2;
}

.nav-wrap {
  display: flex;
  align-content: center;
  align-items: center;
}
.nav-wrap .menu-wrap {
  position: relative;
  font-size: 16px;
  font-weight: 600;
}
.nav-wrap .menu-wrap:hover .menu-box {
  display: block;
}
.nav-wrap .menu-wrap .menu-title {
  width: 100%;
  height: 40px;
  background-color: #fff;
  padding: 0 24px;
  line-height: 38px;
  font-size: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-bottom: 2px solid #000;
}
.nav-wrap .menu-wrap .menu-title i {
  margin-left: 6px;
  font-weight: bold;
  font-size: 14px;
  transform: translateY(2px);
}
.nav-wrap .menu-wrap .menu-box {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.nav-wrap .menu-wrap .menu-box .menu-main-box {
  width: 100%;
  background: #fff;
}
.nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item {
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  color: var(--font-title);
  padding: 0 12px;
  border-bottom: 1px solid var(--border-deep);
  cursor: pointer;
}
.nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item .icon-jump {
  font-size: 12px;
  color: #ccc;
  pointer-events: none;
}
.nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item:hover {
  background: var(--color-hover);
  color: var(--color);
}
.nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item:hover .goto {
  color: var(--color);
}
.nav-wrap .nav-box {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
}
.nav-wrap .nav-box .nav-item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
  height: 38px;
  line-height: 34px;
  margin: 0 28px 2px;
  cursor: pointer;
  color: #333;
}
.nav-wrap .nav-box .nav-item:hover, .nav-wrap .nav-box .nav-item.active {
  color: var(--color);
}
.nav-wrap .nav-box .nav-item .nav-item-num {
  position: absolute;
  left: 28px;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}

.menu-sub-box {
  display: none;
  position: absolute;
  top: 0;
  left: 164px;
  width: 1036px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  line-height: 1;
  font-size: 0;
  cursor: pointer;
  z-index: 2;
  overflow: auto;
  padding: 0 24px;
}
.menu-sub-box dl {
  width: calc(50% - 6px);
  display: inline-block;
  vertical-align: top;
}
.menu-sub-box dl:nth-child(2n-1) {
  margin-right: 12px;
}
.menu-sub-box dt {
  padding: 24px 0 12px;
  color: var(--font-title);
  border-bottom: 1px solid var(--border-middle);
  font-size: 14px;
  font-weight: bold;
}
.menu-sub-box dt:hover {
  color: var(--color);
  background-color: var(--color-hover);
}
.menu-sub-box dd {
  display: inline-block;
  padding: 0 10px;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  color: var(--font-normal);
  margin-right: 8px;
  position: relative;
}
.menu-sub-box dd .short-line {
  display: inline-block;
  width: 1px;
  background: var(--border-deep);
  position: absolute;
  height: 16px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.menu-sub-box dd:hover {
  color: var(--color);
  background-color: var(--color-hover);
}
.menu-sub-box .nav-service-item-box {
  padding: 16px 0;
  font-size: 0;
}
.menu-sub-box .item-box-card {
  display: inline-block;
  vertical-align: top;
  width: 144px;
  line-height: 20px;
  margin-right: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: var(--font-title);
  text-align: center;
}
.menu-sub-box .item-box-card:hover {
  color: var(--color);
}
.menu-sub-box .item-box-card:nth-child(6n) {
  margin-right: 0;
}
.menu-sub-box .active {
  color: var(--color-active);
}

.menu-active {
  display: block;
}

.menu-brand-item-box {
  padding: 52px 0 16px;
  font-size: 0;
  position: relative;
  height: 100%;
}
.menu-brand-item-box p {
  margin-bottom: 0;
}
.menu-brand-item-box .first-letter-box {
  position: absolute;
  top: 16px;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: var(--font-title);
  margin-bottom: 12px;
  z-index: 2;
}
.menu-brand-item-box .first-letter-box .letter {
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  border: 1px solid transparent;
  cursor: pointer;
}
.menu-brand-item-box .first-letter-box .letter:hover {
  color: var(--color);
}
.menu-brand-item-box .first-letter-box .letter.active {
  border-color: var(--color);
  color: var(--color);
}
.menu-brand-item-box .brand-item-box-main {
  height: 100%;
  overflow: auto;
}
.menu-brand-item-box .brand-box-card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 120px;
  height: 70px;
  border: 1px solid var(--border-deep);
  cursor: pointer;
  transition: all 0.4s ease-out;
  word-break: break-all;
}
.menu-brand-item-box .brand-box-card:hover .info-box {
  opacity: 1;
  transition: opacity 0.3s linear;
}
.menu-brand-item-box .brand-box-card .img-box {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
}
.menu-brand-item-box .brand-box-card .img-box span {
  font-size: 14px;
  color: var(--font-title);
  padding: 0 16px;
  font-weight: bold;
}
.menu-brand-item-box .brand-box-card .img-box img {
  max-width: 100%;
  max-height: 100%;
}
.menu-brand-item-box .brand-box-card .info-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.menu-brand-item-box .brand-box-card .info-box .title-box {
  padding: 0 8px;
  text-align: center;
}
.menu-brand-item-box .brand-box-card .info-box .title {
  font-size: 14px;
  line-height: 20px;
}
.menu-brand-item-box .brand-box-card .info-box .tips {
  font-size: 12px;
  line-height: 18px;
}

.affix-box {
  position: fixed;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.affix-box .affix-item {
  width: 36px;
  height: 36px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 16px;
}
.affix-box .affix-item:last-child {
  margin-bottom: 0;
}
.affix-box .affix-item:hover .iconfont {
  color: var(--color);
}
.affix-box .affix-item .iconfont {
  font-size: 24px;
  line-height: 36px;
  color: #9B9B9B;
}
.affix-box .qrcode-box {
  text-align: center;
}
.affix-box .qrcode-box .img-box {
  width: 136px;
  margin-top: 12px;
}
.affix-box .qrcode-box .img-box img {
  height: 136px;
}
.affix-box .qrcode-box .img-box .txt {
  font-size: 12px;
  color: var(--font-tips);
  margin-top: 8px;
}
.affix-box .service-box {
  text-align: left;
}
.affix-box .service-box dt {
  font-size: 12px;
  color: var(--font-tips);
  margin-bottom: 10px;
}
.affix-box .service-box dd {
  margin-bottom: 8px;
}
.affix-box .service-box dd:hover {
  color: var(--color);
}
.affix-box .service-box dd:hover .txt {
  color: var(--color);
}
.affix-box .service-box dd .svg-icon {
  width: 16px;
  height: 16px;
}
.affix-box .service-box dd .tel {
  font-size: 16px;
  font-weight: bold;
}

#aqara-chatbot-bubble-window {
  right: 60px !important;
}
.svg-icon {
  width: 44px;
  height: 44px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
